import React, { useContext, useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { phoneNumberInputHandler, sanitizeInstagramUsername, sanitizeUsername } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './StorePrimaryDetails.module.css'

const StorePrimaryDetails = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [isEditing, setIsEditing] = useState(false)
    const [storeDetails, setStoreDetails] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false)

    const getStoreDetails = async () => {
        const getStoreDetailsResponse = await fetch(BASE_URL_API + "getStoreDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getStoreDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStoreDetailsRespo = await getStoreDetailsResponse.json()
            if (getStoreDetailsRespo.status === 'success') {
                setStoreDetails(getStoreDetailsRespo?.response)
            } else {
                if (getStoreDetailsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setStoreDetails(null)
                }
            }
        }
    }


    useEffect(() => {
        getStoreDetails()
    }, [authCtx.storeIdentifier])

    const updateDetailsHandler = async () => {
        console.log(storeDetails)
        setIsUpdating(true)

        const updateStoreDetailsResponse = await fetch(BASE_URL_API + "updateStoreDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    dataToUpdate: storeDetails
                })
            });

        if (!updateStoreDetailsResponse.ok) {
            console.log('Something went wrong : Server Error')
        } else {
            const updateStoreDetailsRespo = await updateStoreDetailsResponse.json()
            if (updateStoreDetailsRespo.status === "success") {
                toast('Successfully updated!')
            } else {
                if (updateStoreDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
                console.log(updateStoreDetailsRespo?.response)
            }
        }
        setIsEditing(false)
        setIsUpdating(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.coverImageWrapper}>
                    <img src="https://img.freepik.com/free-photo/scissors-hair-trimmer-copy-space_23-2148352943.jpg?t=st=1740747515~exp=1740751115~hmac=a5b4ac4871a11420b57bed9a1a7e88519ab6ebc8390c42b0fbfa764f35ede886&w=2000" />
                    <div className={styles.editCoverImg}>Update Cover</div>
                </div>
                <div className={styles.bodyWrapper}>
                    <div className={styles.firstRowWrapper}>
                        <div className={styles.logoWrapper}>
                            <div className={styles.logoBoxContainer}>
                                <div className={styles.logoBox}>
                                    <img src={storeDetails?.logo} />
                                </div>

                            </div>
                        </div>
                        <div className={styles.nameWrapper}>
                        </div>
                        <div className={styles.actionWrapper} >
                            <a href="#">View Profile</a>
                        </div>

                    </div>
                    <div className={styles.rowWrapper}>
                        <div className={`${styles.rowContainer} ${styles.mainActionWrapper} `}>
                            <div>
                                <div className={styles.mawTitle}>
                                    Store Profile
                                </div>
                                <div className={styles.mawSubtitle}>
                                    Update your store details here
                                </div>
                            </div>
                            <div>
                                {isEditing === false ?
                                    <div>
                                        <button className={styles.editBtn} onClick={() => setIsEditing(true)} >Edit</button>
                                    </div>
                                    :
                                    <div className={styles.mawBtnWrapper}>
                                        <button className={styles.editBtn} onClick={() => setIsEditing(false)} >Cancel</button>
                                        {isUpdating === true ?
                                            <button className={styles.saveBtn} >
                                                <FaSpinner className={styles.spinner} /> Saving
                                            </button>
                                            :
                                            <button className={styles.saveBtn} onClick={() => updateDetailsHandler()}  >
                                                Save Changes
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={styles.rowWrapper}>
                        <div className={`${styles.rowContainer} ${styles.mainActionWrapper} `}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.captionTitle} >Public Name</div>
                                <div className={styles.captionSubtitle} >This will be displayed across invoices, messages, feedback forms, and etc.</div>

                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={styles.nameAddressWrapper}>

                                    <div className={styles.publicNameWrapper}>
                                        <input placeholder="Enter store name..." value={storeDetails?.storeName}
                                            onChange={(e) => setStoreDetails({ ...storeDetails, storeName: e.target.value })}
                                            readOnly={!isEditing}
                                        />
                                    </div>

                                    <div className={styles.addressWrapper}>
                                        <textarea rows={3} placeholder="Please enter full address..." value={storeDetails?.storeAddress}
                                            onChange={(e) => setStoreDetails({ ...storeDetails, storeAddress: e.target.value })}
                                            readOnly={!isEditing}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className={styles.rowWrapper}>
                        <div className={`${styles.rowContainer} ${styles.mainActionWrapper} `}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.captionTitle} >Store Phone Number(s)</div>
                                <div className={styles.captionSubtitle} >Only primary number will be displayed across invoices, messages, feedback forms, and etc.</div>

                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={styles.phoneNumbersWrapper}>

                                    <div className={styles.eachPhoneWrapper}>
                                        <div className={styles.epwCaption}>Primary </div>
                                        <div className={styles.epwField}>
                                            <input placeholder="Enter Primary Number " value={storeDetails?.storePrimaryPhone}
                                                onChange={(e) => setStoreDetails({ ...storeDetails, storePrimaryPhone: phoneNumberInputHandler(e.target.value) })}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachPhoneWrapper}>
                                        <div className={styles.epwCaption}>Secondary </div>
                                        <div className={styles.epwField}>
                                            <input placeholder="Enter Secondary Number " value={storeDetails?.storeSecondaryPhone}
                                                onChange={(e) => setStoreDetails({ ...storeDetails, storeSecondaryPhone: phoneNumberInputHandler(e.target.value) })}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachPhoneWrapper}>
                                        <div className={styles.epwCaption}>Tertiary </div>
                                        <div className={styles.epwField}>
                                            <input placeholder="Enter Tertiary Number " value={storeDetails?.storeTertiaryPhone}
                                                onChange={(e) => setStoreDetails({ ...storeDetails, storeTertiaryPhone: phoneNumberInputHandler(e.target.value) })}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className={styles.rowWrapper}>
                        <div className={`${styles.rowContainer} ${styles.mainActionWrapper} `}>
                            <div className={styles.captionWrapper}>
                                <div className={styles.captionTitle} >Social Profiles</div>
                                <div className={styles.captionSubtitle} >Your social media profiles be displayed across invoices, messages, feedback forms, and etc.</div>

                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={styles.socialWrapper}>
                                    <div className={styles.eachSocialWrapper}>
                                        <div className={styles.epwCaption}>Instagram.com/ </div>
                                        <div className={styles.epwField}>
                                            <input placeholder="Instagram Profile URL " value={storeDetails?.instagramUrl}
                                                onChange={(e) => setStoreDetails({ ...storeDetails, instagramUrl: sanitizeInstagramUsername(e.target.value) })}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.eachSocialWrapper}>
                                        <div className={styles.epwCaption}>Google Map Link </div>
                                        <div className={styles.epwField}>
                                            <input placeholder="Map link https://g.maps.google.com/ " value={storeDetails?.mapLink}
                                                onChange={(e) => setStoreDetails({ ...storeDetails, mapLink: e.target.value })}
                                                readOnly={!isEditing}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className={styles.thatsAllWrapper}>
                        That's all!
                    </div>



                </div>
            </div>
        </div>
    )
}

export default StorePrimaryDetails