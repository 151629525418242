import React from 'react'
import styles from './FeedbackSettings.module.css'

const FeedbackSettings = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainTitle}>
                        Settings
                    </div>
                    <div>
                        <div className={styles.leftContainer}>

                        </div>
                        <div className={styles.rightContainer}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackSettings