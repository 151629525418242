import React, { useContext, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './MESAShiftManager.module.css'

const MESAShiftManager = (props) => {
    const { isOpen, staffIdentifier, onClose, generalShift, weekOff } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [isUpdating, setIsUpdating] = useState(false)

    window.onclick = event => event.target.id === "mesashiftManagerPop" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const [weekdays, setWeekdays] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])
    const [shiftData, setShiftData] = useState({
        shiftStartTime: generalShift?.startTime,
        shiftEndTime: generalShift?.endTime,
        weekOff: weekOff
    })


    const updateShiftHandler = async () => {
        if (staffIdentifier !== '') {
            if (shiftData?.shiftStartTime !== '' && shiftData?.shiftEndTime !== '') {
                setIsUpdating(true)
                console.log(staffIdentifier, shiftData)
                const manageStaffShiftResponse = await fetch(BASE_URL_API + "manageStaffShift",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            staffIdentifier: staffIdentifier,
                            shiftData: shiftData
                        })
                    });

                if (!manageStaffShiftResponse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const manageStaffShiftRespo = await manageStaffShiftResponse.json()
                    if (manageStaffShiftRespo.status === "success") {
                        toast(`Successfully updated the shift data!`)
                        onClose()
                    } else {
                        if (manageStaffShiftRespo.message === "tokenExpired") {
                            authCtx.logout()
                        }
                        console.log(manageStaffShiftRespo)
                    }
                }
                setIsUpdating(false)
            } else {
                alert(`Shift time can't be blank`)
            }
        } else {
            alert(`You can't leave the staff parameters blank, kindly refresh the page first.`)
        }
    }




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="mesashiftManagerPop">
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div className={styles.mainHeaderContainer} >
                                <div className={styles.mainHeaderTitle} >Manage Staff Shift</div>
                                <div onClick={() => onClose()} className={styles.closeBtn} >
                                    <button>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.shiftTimeWrapper}>
                            <div className={styles.sectionTitle} >
                                General Shift
                            </div>
                            <div className={styles.shiftTimeContainer}>
                                <div className={styles.eachInputWrapper}>
                                    <div className={styles.shiftTitle}>Shift Start Time</div>
                                    <div className={styles.inputField} >
                                        <input type="time" value={shiftData?.shiftStartTime}
                                            onChange={(e) => setShiftData({
                                                ...shiftData,
                                                shiftStartTime: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.eachInputWrapper}>
                                    <div className={styles.shiftTitle}>Shift End Time</div>
                                    <div className={styles.inputField} >
                                        <input type="time" value={shiftData?.shiftEndTime}
                                            onChange={(e) => setShiftData({
                                                ...shiftData,
                                                shiftEndTime: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.weekOffWrapper} >
                            <div className={styles.sectionTitle} >Week off</div>
                            <div className={styles.weekdaysWrapper}>
                                {weekdays?.map((item, index) => (
                                    <div className={shiftData?.weekOff === item ? styles.eachDayWrapperSelected : styles.eachDayWrapper} key={index}
                                        onClick={() => setShiftData({
                                            ...shiftData,
                                            weekOff: item
                                        })}
                                    >
                                        <div className={styles.dayIcon} >
                                            {shiftData?.weekOff === item ?
                                                <MdOutlineRadioButtonChecked />
                                                :
                                                <MdOutlineRadioButtonUnchecked />
                                            }
                                        </div>
                                        <div>{item}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.updateBtn}>
                            {isUpdating === false ?
                                <button onClick={() => updateShiftHandler()} >Update</button>
                                :
                                <button className={styles.saveBtn} >
                                    <FaSpinner className={styles.spinner} /> Updating...
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MESAShiftManager