import React, { useContext, useEffect, useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import { FaSpinner, FaWhatsapp } from 'react-icons/fa'
import { MdMail, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { BASE_URL_API } from '../../../References/URLs'
import ActiveStoreContext from '../../../store/ActiveStoreContext'
import AuthContext from '../../../store/auth-context'
import styles from './SendReportsInterface.module.css'
import { PiWarning } from "react-icons/pi";
import { Link, useLocation } from 'react-router'
import { toast } from 'react-toastify'


const SendReportsInterface = (props) => {
    const { isOpen, onClose } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isSendingReport, setIsSendingReport] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [isConfigurationDone, setIsConfigurationDone] = useState(false)
    const [receiversData, setReceiversData] = useState(null)
    const location = useLocation();

    useEffect(() => {
        if (isOpen) {
            if(location.pathname===`/config/daily-reports`)
            onClose();
        }
    }, [location.pathname]);


    const sendClosureReport = async () => {
        setIsSendingReport(true)
        const sendClosureReportResponse = await fetch(BASE_URL_API + "sendClosureReport",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    storeName: activeStore.storeName,
                    storeAddress: activeStore.storeAddress,
                    authIdentifier: authCtx.authIdentifier
                })
            });

        if (!sendClosureReportResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const sendClosureReportRespo = await sendClosureReportResponse.json()
            if (sendClosureReportRespo.status === "success") {
                toast(`Today's Closure Report has been successfully sent!`)
                onClose()
            } else {
                if (sendClosureReportRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(sendClosureReportRespo)
        }
        setIsSendingReport(false)
    }

    const validateDailyReportConfiguration = async () => {
        setIsValidating(true)
        const validateDailyReportConfigurationResponse = await fetch(BASE_URL_API + "validateDailyReportConfiguration",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                })
            });

        if (!validateDailyReportConfigurationResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const validateDailyReportConfigurationRespo = await validateDailyReportConfigurationResponse.json()
            if (validateDailyReportConfigurationRespo?.status === "success") {
                setIsConfigurationDone(true)
                setReceiversData(validateDailyReportConfigurationRespo?.response)
            } else {
                if (validateDailyReportConfigurationRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else if (validateDailyReportConfigurationRespo.response === null) {
                    setIsConfigurationDone(false)
                }
            }
            console.log(validateDailyReportConfigurationRespo)
        }
        setIsValidating(false)
    }

    useEffect(() => {
        if (isOpen) validateDailyReportConfiguration()
    }, [isOpen])

    

    if (!isOpen) {
        return null
    }

    if (isValidating) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} >
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Send Daily Closure Reports
                        </div>
                        <div className={styles.mainBody}>
                            <div className={styles.validatingSpinner}>
                                <BiLoaderAlt size={34} className={styles.spinnerfast} />
                            </div>
                            <div className={styles.validatingTxt}>
                                Hold On!
                            </div>
                            <div className={styles.validatingMsg}>
                                Validating Configurations...
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.actionWrapper}>
                                <button className={styles.cancel} onClick={() => onClose()} >Cancel</button>
                                <button className={`${styles.send} ${styles.disabledSend}`} disabled ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} >
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        Send Closure Reports
                    </div>
                    <div className={styles.mainBody}>
                        {isConfigurationDone === true ?
                            <div className={styles.platformWrapper}>
                                <div className={styles.eachPlatform} >
                                    <FaWhatsapp size={22} />
                                    {receiversData?.filter(itemRec => itemRec.deliveryPlatform==="WhatsApp")?.length} Number(s) Found
                                </div>
                                <div className={styles.eachPlatform} >
                                    <MdMail size={22} />
                                    {receiversData?.filter(itemRec => itemRec.deliveryPlatform==="E-mail")?.length} E-mail(s) Found!
                                </div>
                            </div>
                            :
                            <div className={styles.platformWrapper}>
                                <PiWarning size={20} />
                                <div className={styles.notConfiguredYet}>
                                    You haven't configured Daily reports yet!
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.actionWrapper}>
                            <button className={styles.cancel} onClick={() => onClose()} >Cancel</button>
                            {isSendingReport === true ?
                                <button className={styles.send}>
                                    <FaSpinner className={styles.spinner} /> Saving
                                </button>
                                :
                                isConfigurationDone === true ?
                                    <button className={styles.send} onClick={() => sendClosureReport()} >Send Now</button>
                                    :
                                    <Link to={`/config/daily-reports`} className={styles.config} >Configure Now</Link>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendReportsInterface