import React, { useState, useEffect, useContext } from 'react';
import styles from './ComCreateCampaigns.module.css';
import { FaCheckCircle, FaRegCircle, FaSpinner } from 'react-icons/fa'; // React Icons
import CampaignDetails from '../../Components/Communications/Campaigns/CampaignDetails';
import MessageTemplates from '../../Components/Communications/Campaigns/MessageTemplates';
import CampaignAudience from '../../Components/Communications/Campaigns/CampaignAudience';
import CampaignScheduler from '../../Components/Communications/Campaigns/CampaignScheduler';
import CampaignLaunch from '../../Components/Communications/Campaigns/CampaignLaunch';
import CampaignPerformance from '../../Components/Communications/Campaigns/CampaignPerformance';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { Link, useParams } from 'react-router';
import { GoArrowLeft } from "react-icons/go";


const ComCreateCampaigns = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const { campaignIdentifier, currentStep } = useParams()
    // const [currentStep, setCurrentStep] = useState(1);
    const [campaignData, setCampaignData] = useState({
        name: '',
        messageTemplate: '',
        audience: '',
        deliveryMessageSource: '',
        schedule: '',
        status: 'draft',
    });
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const progress = (currentStep / 5) * 100;

    useEffect(() => {
        // Mock loading state to simulate template loading
        if (currentStep === 'templates') {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 2000); // Simulate loading delay
        }
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep !== 'launch') {
            // setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            // setCurrentStep(currentStep - 1);
        }
    };

    const saveCampaign = () => {
        // Mock saving functionality
        setIsSaved(true);
        console.log('Campaign Saved:', campaignData);
        setTimeout(() => setIsSaved(false), 3000); // Reset after a few seconds
    };

    const handleSidebarClick = (step) => {
        // setCurrentStep(step);
    };



    if (!activeStore) {
        return (
            <div className={styles.container} >
                Please select a audience!
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {/* Left Sidebar */}
            <div className={styles.sidebar}>
                <Link to={`/communications/all-campaigns`}>
                    <div className={styles.goToAllCampaigns}>
                    <GoArrowLeft size={20} /> Go To All Campaigns
                    </div>
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/details`}
                    className={`${styles.sidebarItem} ${currentStep === 'details' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Campaign
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/templates`}
                    className={`${styles.sidebarItem} ${currentStep === 'templates' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Message Template
                    {currentStep === 'templates' && loading && (
                        <span className={styles.loadingIcon}>
                            <FaSpinner className={styles.spinner} />
                        </span>
                    )}
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/audience`}
                    className={`${styles.sidebarItem} ${currentStep === 'audience' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Audience
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/scheduler`}
                    className={`${styles.sidebarItem} ${currentStep === 'scheduler' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Scheduler
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/launch`}
                    className={`${styles.sidebarItem} ${currentStep === 'launch' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Launch
                </Link>
                <Link to={`/communications/campaign/${campaignIdentifier}/performance`}
                    className={`${styles.sidebarItem} ${currentStep === 'performance' ? styles.active : ''}`}
                >
                    <FaRegCircle /> Performance
                </Link>
            </div>

            {/* Main Content */}
            <div className={styles.content}>
                {currentStep === 'details' && <CampaignDetails data={campaignData} setData={setCampaignData} />}
                {currentStep === 'templates' && <MessageTemplates data={campaignData} setData={setCampaignData} />}
                {currentStep === 'audience' && <CampaignAudience data={campaignData} setData={setCampaignData} />}
                {currentStep === 'scheduler' && <CampaignScheduler data={campaignData} setData={setCampaignData} />}
                {currentStep === 'launch' && <CampaignLaunch data={campaignData} setData={setCampaignData} />}
                {currentStep === 'performance' && <CampaignPerformance data={campaignData} setData={setCampaignData} />}
            </div>

            {/* Footer and Progress */}
            <div className={styles.progressContainer}>
                <div className={styles.progressBarWrapper}>
                    <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
                </div>
            </div>

            <div className={styles.footer}>
                <button
                    onClick={prevStep}
                    disabled={currentStep === 1}
                    className={`${styles.footerBtn} ${currentStep === 1 ? styles.disabled : ''}`}
                >
                    Prev
                </button>
                <button
                    onClick={nextStep}
                    className={`${styles.footerBtn} ${currentStep === 5 ? styles.disabled : ''}`}
                >
                    Next
                </button>
                <button
                    onClick={saveCampaign}
                    className={`${styles.footerSaveBtn} ${isSaved ? styles.saved : ''}`}
                >
                    {isSaved ? 'Saved!' : 'Save Progress'}
                </button>
            </div>
        </div>
    );
};

export default ComCreateCampaigns;
