import React, { useContext, useEffect, useState } from 'react'
import { FaSpinner, FaToggleOff, FaToggleOn, FaWhatsapp } from 'react-icons/fa'
import { MdDelete, MdMail } from 'react-icons/md'
import { toast } from 'react-toastify'
import DRCEachReceiver from '../../Components/Settings/DRCEachReceiver'
import { countryCodesHolder } from '../../Helper/countryCodes'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './DailyReportsConfiguration.module.css'

const DailyReportsConfiguration = () => {

    const [platformOptions, setPlatformOptions] = useState([
        { title: 'WhatsApp', icon: <FaWhatsapp fontSize={25} /> },
        { title: 'E-mail', icon: <MdMail fontSize={25} /> }
    ])

    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [activePlatform, setActivePlatform] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const [receiversData, setReceiversData] = useState([])
    const [isAdding, setIsAdding] = useState(false)
    const [receiver, setReceiver] = useState('')
    const [countryCode, setCountryCode] = useState('+91')


    // getReceiversForDailyReports

    const getReceiversForDailyReports = async () => {
        setIsFetching(true)
        const getReceiversForDailyReportsResponse = await fetch(BASE_URL_API + "getReceiversForDailyReports",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                })
            });

        if (!getReceiversForDailyReportsResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const getReceiversForDailyReportsRespo = await getReceiversForDailyReportsResponse.json()
            if (getReceiversForDailyReportsRespo?.status === "success") {
                setReceiversData(getReceiversForDailyReportsRespo?.response)
            } else {
                if (getReceiversForDailyReportsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else if (getReceiversForDailyReportsRespo.response === null) {
                    setReceiversData([])
                }
            }
            console.log(getReceiversForDailyReportsRespo)
        }
        setIsFetching(false)
    }

    const addReceiverForDailyReports = async () => {
        setIsAdding(true)
        const addReceiverForDailyReportsResponse = await fetch(BASE_URL_API + "addReceiverForDailyReports",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    countryCode: countryCode,
                    deliveryPlatform: activePlatform.title,
                    receiver: receiver
                })
            });

        if (!addReceiverForDailyReportsResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const addReceiverForDailyReportsRespo = await addReceiverForDailyReportsResponse.json()
            if (addReceiverForDailyReportsRespo?.status === "success") {
                setReceiver('')
                getReceiversForDailyReports()
                toast(`Successfully added the receiver!`)
            } else {
                if (addReceiverForDailyReportsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(addReceiverForDailyReportsRespo)
        }
        setIsAdding(false)
    }

    useEffect(() => {
        getReceiversForDailyReports()
    }, [activePlatform])






    return (
        <div className={styles.mainWrapper}>
            <div className={styles.header}>
                Daily Reports Configuration
            </div>
            <div>
                <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>
                        Platform
                    </div>
                    <div className={styles.sectionSubtitle}>
                        Choose a platform where you want your reports to be delivered!
                    </div>
                </div>
                <div className={styles.sectionBodyWrapper}>
                    {platformOptions?.map((item, index) => (
                        <div className={`${styles.eachPlatformWrapper} ${activePlatform?.title === item?.title && styles.activePlatform}`}
                            key={index}
                            onClick={() => setActivePlatform(item)}
                        >
                            <div>
                                <div>
                                    {item?.icon}
                                </div>
                                <div className={styles.platformTitle} >{item?.title}</div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

            {activePlatform !== null &&
                <div>
                    <div className={styles.headerWrapper}>
                        <div className={styles.sectionHeader}>
                            <div className={styles.sectionTitle}>
                                Receiver(s)
                            </div>
                            <div className={styles.sectionSubtitle}>
                                Manage all the receivers who'll get the reports on {activePlatform?.title}
                            </div>
                        </div>
                        <div>
                            {activePlatform?.title === "WhatsApp" &&
                                <div className={styles.eachFormWrapper}>
                                    <select onChange={(e) => setCountryCode(e.target.value)}
                                        defaultValue="+91"
                                    >
                                        {countryCodesHolder?.map((itemCc, indexCc) => (
                                            <option key={indexCc} value={itemCc?.code}>{itemCc?.country} ({itemCc?.code})</option>
                                        ))}
                                    </select>
                                    <div className={styles.formWrapperInput}>
                                        <input placeholder="Enter 10 digit number..." autoFocus type="number" maxLength={10}
                                            value={receiver}
                                            onChange={(e) => setReceiver(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.addBtnWrapper}>
                                        {isAdding === true ?
                                            <button className={styles.addBtn} disabled ><FaSpinner className={styles.spinner} /> Adding..</button>
                                            :
                                            <button className={styles.addBtn} onClick={() => addReceiverForDailyReports()} >Add</button>
                                        }
                                    </div>
                                </div>
                            }
                            {activePlatform?.title === "E-mail" &&
                                <div className={styles.eachFormWrapper}>
                                    <div className={styles.formWrapperInput}>
                                        <input placeholder="Enter your e-mail..."
                                            value={receiver}
                                            onChange={(e) => setReceiver(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.addBtnWrapper}>
                                        {isAdding === true ?
                                            <button className={styles.addBtn} disabled ><FaSpinner className={styles.spinner} /> Adding..</button>
                                            :
                                            <button className={styles.addBtn} onClick={() => addReceiverForDailyReports()} >Add</button>
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className={styles.dataWrapper}>
                        {receiversData?.filter(thisItem => thisItem.deliveryPlatform === activePlatform.title).map((item, index) => (
                            <DRCEachReceiver item={item} getReceiversForDailyReports={getReceiversForDailyReports} />
                        ))}

                        {receiversData?.filter(thisItem => thisItem.deliveryPlatform === activePlatform.title)?.length > 0 ?
                            <div className={styles.thatsAll}>
                                That's all!
                            </div>
                            :
                            <div className={styles.thatsAll}>
                                You haven't added any receiver yet!
                            </div>
                        }

                    </div>
                </div>
            }
        </div>
    )
}

export default DailyReportsConfiguration