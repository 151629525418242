import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useUnloadWarning = (isFormDirty) => {
    const navigate = useNavigate();

    // 1️⃣ Block Link Clicks Before URL Change
    useEffect(() => {
        const handleClick = (event) => {
            if (!isFormDirty) return;

            const target = event.target.closest("a");
            if (target && target.href && !target.target) {
                event.preventDefault();
                const confirmLeave = window.confirm("Are you sure you want to leave?");
                if (confirmLeave) {
                    navigate(target.getAttribute("href"));
                }
            }
        };

        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, [isFormDirty, navigate]);

    // 2️⃣ Block Browser Refresh & Tab Close
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isFormDirty) {
                event.preventDefault();
                event.returnValue = "Are you sure you want to leave?";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [isFormDirty]);

    // 3️⃣ Block Back/Forward Button
    useEffect(() => {
        const handlePopState = (event) => {
            if (isFormDirty && !window.confirm("Are you sure you want to leave?")) {
                event.preventDefault();
                window.history.pushState(null, "", window.location.href);
            }
        };

        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [isFormDirty]);
};

export default useUnloadWarning;
