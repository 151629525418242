import React, { useContext, useState } from 'react'
import { FaSpinner, FaToggleOff, FaToggleOn } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './DRCEachReceiver.module.css'

const DRCEachReceiver = (props) => {
    const { item, getReceiversForDailyReports } = props
    const [isDeleting, setIsDeleting] = useState(false)
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const deleteReceiverForDailyReports = async (k) => {
        setIsDeleting(true)
        const deleteReceiverForDailyReportsResponse = await fetch(BASE_URL_API + "deleteReceiverForDailyReports",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    receiverIdentifier: k,
                })
            });

        if (!deleteReceiverForDailyReportsResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const deleteReceiverForDailyReportsRespo = await deleteReceiverForDailyReportsResponse.json()
            if (deleteReceiverForDailyReportsRespo?.status === "success") {
                getReceiversForDailyReports()
                toast(`Successfully deleted the receiver!`)
            } else {
                if (deleteReceiverForDailyReportsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(deleteReceiverForDailyReportsRespo)
        }
        setIsDeleting(false)
    }

    const toggleReceiverForDailyReports = async (k) => {
        const toggleReceiverForDailyReportsResponse = await fetch(BASE_URL_API + "toggleReceiverForDailyReports",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    receiverIdentifier: k,
                })
            });

        if (!toggleReceiverForDailyReportsResponse.ok) {
            console.log("Something went wrong : Server Wrong")
        } else {
            const toggleReceiverForDailyReportsRespo = await toggleReceiverForDailyReportsResponse.json()
            if (toggleReceiverForDailyReportsRespo?.status === "success") {
                getReceiversForDailyReports()
                toast(`Successfully updated the receiver status!`)
            } else {
                if (toggleReceiverForDailyReportsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(toggleReceiverForDailyReportsRespo)
        }
    }



    return (
        <div className={styles.eachReceiver} >
            <div className={styles.contentWrapper}>
                <div className={styles.toggleWrapper}
                    onClick={() => toggleReceiverForDailyReports(item.receiverIdentifier)}
                >
                    {item?.isActive === '1' ?
                        <FaToggleOn size={28} color="#027fff" />
                        :
                        <FaToggleOff size={28} />
                    }
                </div>
                <div className={styles.detailsWrapper}>
                    <div className={styles.receiver}>
                        {item?.countryCode !== null && `${item.countryCode}-`}{item?.receiver}
                    </div>
                    {item?.isActive === "1" ?
                        <div className={styles.receiverTxt}>
                            Active since {item?.addedOn}
                        </div>
                        :
                        <div className={styles.receiverTxt}>
                            De-activated since {item?.deactivatedOn}, hence this receiver is not getting the daily reports.
                        </div>
                    }
                </div>
            </div>
            {isDeleting === false ?
                <div className={styles.actionWrapper} onClick={() => deleteReceiverForDailyReports(item.receiverIdentifier)} >
                    <MdDelete size={22} />
                </div>
                :
                <div className={styles.actionWrapper}  >
                    <FaSpinner className={styles.spinner} /> Deleting...
                </div>
            }
        </div>
    )
}

export default DRCEachReceiver