import React, { useEffect, useRef } from 'react';
import { getAttendanceSummary, getMonthNumber, padNumber } from '../../Helper/calculations';
import styles from './MESAList.module.css';

const MESAList = ({ days, attendanceRecords, selectedMonth, selectedYear, triggeredDateHandler }) => {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.toLocaleString('en-US', { month: 'long' }); // 'January', 'February', etc.
    const currentYear = today.getFullYear();
    const currentDayName = today.toLocaleString('en-US', { weekday: 'long' });

    const currentDayRef = useRef(null);
    const listWrapperRef = useRef(null);

    // Check if the selected month & year match today's month & year
    const isCurrentMonthAndYear = selectedMonth === currentMonth && Number(selectedYear) === currentYear;

    useEffect(() => {
        if (isCurrentMonthAndYear && currentDayRef.current && listWrapperRef.current) {
            setTimeout(() => {
                currentDayRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            }, 300);
        }
    }, [days, selectedMonth, selectedYear]); // Ensure effect runs on month/year change

    const dateClickHandler = (k) => {
        triggeredDateHandler(k)
        console.log(k)
    }

    return (
        <div ref={listWrapperRef} className={styles.listWrapper}>
            {days?.map((item, index) => {
                const isToday = isCurrentMonthAndYear && item.date === currentDay && item.day === currentDayName;
                const buildDateThis = `${selectedYear}-${getMonthNumber(selectedMonth)}-${padNumber(item?.date)}`
                const entriesForThis = attendanceRecords?.find((attRecFil) => attRecFil?.dateToBeMarked === buildDateThis)?.attendanceEntries
                return (
                    <div
                        key={index}
                        ref={isToday ? currentDayRef : null}
                        className={`${styles.eachDayWrapper} ${isToday ? styles.highlight : ''}`}

                        onClick={() => dateClickHandler(item)}
                    >
                        <div className={styles.titleWrapper}>
                            <div className={styles.day}>
                                {item?.day?.substr(0, 3)}
                            </div>
                            <div className={styles.date}>
                                {item?.date}
                            </div>
                        </div>
                        <div className={styles.detailWrapper}>
                            <div className={styles.firstInWrapper}>
                                <div className={styles.timeCaption}>First Check-In</div>
                                <div>
                                    {getAttendanceSummary(entriesForThis)?.firstCheckIn}
                                </div>
                            </div>
                            <div className={styles.timelineWrapper}>
                                <div className={styles.connectingLine} ></div>
                            </div>
                            <div className={styles.lastOutWrapper}>
                                <div className={styles.timeCaption}>Last Check out</div>
                                <div>
                                    {getAttendanceSummary(entriesForThis)?.lastCheckOut}
                                </div>
                            </div>
                            <div className={styles.hoursWorkedWrapper}>
                                <div>{getAttendanceSummary(entriesForThis)?.totalHours} </div>
                                <div>Hours Worked</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default MESAList;
