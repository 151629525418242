import React, { useContext, useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../../../References/URLs'
import ActiveStoreContext from '../../../../store/ActiveStoreContext'
import AuthContext from '../../../../store/auth-context'
import ConfirmationPopup from './ConfirmationPopup'
import styles from './GIAlphaTabWrapper.module.css'

const GIAlphaTabWrapper = (props) => {
    const { invoiceDataMaster } = props
    const { sessionIdentifier } = useParams();
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== ""
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    let navigate = useNavigate()
    const [currentSessions, setCurrentSessions] = useState([]);
    const [draggedTab, setDraggedTab] = useState(null);
    const [bookingInitiationStatus, setBookingInitiationStatus] = useState(
        "NOT_INITIATED"
    );
    const [isDeleting, setIsDeleting] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [triggeredBookingTab, setTriggeredBookingTab] = useState(null)

    // Example actions
    const handleDelete = async (k) => {
        setIsProcessing(true);
        const deleteBookingSessionResponse = await fetch(BASE_URL_API + "deleteBookingSession",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: k,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!deleteBookingSessionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteBookingSessionRespo = await deleteBookingSessionResponse.json()
            if (deleteBookingSessionRespo.status === "success") {
                toast("Booking has been successfully deleted!")
                navigate(`/invoice/create`)
                getCurrentSessions()
            } else {
                if (deleteBookingSessionRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
                console.log(deleteBookingSessionRespo)
            }
        }
        setIsProcessing(false);
        setIsPopupOpen(false);
    };

    const handleMarkComplete = async (k) => {
        setIsProcessing(true);
        console.log('Marking booking as complete...');
        const updateSessionStatusResponse = await fetch(BASE_URL_API + "updateSessionStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: k,
                    statusToBeUpdated: 'BOOKING_COMPLETED',
                    storeName : activeStore?.storeName,
                    storePrimaryPhone : activeStore?.storePrimaryPhone
                })
            });

        if (!updateSessionStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateSessionStatusRespo = await updateSessionStatusResponse.json();
            if (updateSessionStatusRespo.status == "success") {
                toast("Booking has been successfully completed!")
                navigate(`/invoice/create`)
                getCurrentSessions()
            } else {
                if (updateSessionStatusRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateSessionStatusRespo);
        }
        setIsProcessing(false);
        setIsPopupOpen(false);
        console.log('Booking marked as complete');
    };
    const getCurrentSessions = async () => {
        const sessionOrder = JSON.parse(localStorage.getItem("sessionOrder")) || [];
        const getCurrentSessionsResponse = await fetch(
            BASE_URL_API + "getCurrentSessions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                }),
            }
        );

        if (!getCurrentSessionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCurrentSessionsRespo = await getCurrentSessionsResponse.json();
            if (getCurrentSessionsRespo.status === "success") {
                const sessions = getCurrentSessionsRespo.response[0] || [];
                const orderedSessions = sessionOrder
                    .map((id) => sessions.find((s) => s.bookingDetails.bookingId === id))
                    .filter(Boolean);
                const newSessions = sessions.filter(
                    (s) =>
                        !sessionOrder.includes(s.bookingDetails.bookingId)
                );
                const updatedSessions = [...orderedSessions, ...newSessions];

                setCurrentSessions(updatedSessions);
                localStorage.setItem("currentSessions", JSON.stringify(updatedSessions));
                localStorage.setItem(
                    "sessionOrder",
                    JSON.stringify(updatedSessions.map((s) => s.bookingDetails.bookingId))
                );
            } else if (getCurrentSessionsRespo.message === "tokenExpired") {
                authCtx.logout();
            }
        }
    };

    useEffect(() => {
        getCurrentSessions()
    }, [sessionIdentifier]);

    const handleDragStart = (index) => setDraggedTab(index);

    const handleDrop = (index) => {
        if (draggedTab !== null) {
            const updatedTabs = [...currentSessions];
            const [removed] = updatedTabs.splice(draggedTab, 1);
            updatedTabs.splice(index, 0, removed);
            setCurrentSessions(updatedTabs);
            localStorage.setItem(
                "currentSessions",
                JSON.stringify(updatedTabs)
            );
            localStorage.setItem(
                "sessionOrder",
                JSON.stringify(updatedTabs.map((s) => s.bookingDetails.bookingId))
            );
            setDraggedTab(null);
        }
    };

    const initiateSession = async () => {
        setBookingInitiationStatus("INITIATING");
        const initiateSessionResponse = await fetch(
            BASE_URL_API + "initiateSession",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    maleCount: 1,
                    femaleCount: 1,
                    othersCount: 0,
                    createdBy: JSON.parse(authCtx?.loginData)?.welcomeData?.displayName,
                    createdByIdentifier: authCtx?.authIdentifier,
                }),
            }
        );

        if (!initiateSessionResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const initiateSessionRespo = await initiateSessionResponse.json();
            if (initiateSessionRespo.status === "success") {
                toast(`New Session Created!`);
                const newSession = {
                    bookingDetails: {
                        bookingId: initiateSessionRespo.response.bookingId,
                    },
                };
                const updatedSessions = [...currentSessions, newSession];
                setCurrentSessions(updatedSessions);
                localStorage.setItem("currentSessions", JSON.stringify(updatedSessions));
                const updatedOrder = [
                    ...JSON.parse(localStorage.getItem("sessionOrder") || "[]"),
                    newSession.bookingDetails.bookingId,
                ];
                localStorage.setItem("sessionOrder", JSON.stringify(updatedOrder));
                setBookingInitiationStatus("NOT_INITIATED");
                navigate(`/invoice/create/` + newSession.bookingDetails.bookingId);
            } else {
                setBookingInitiationStatus("INITIATION_FAILED");
                if (initiateSessionRespo.message === "tokenExpired") {
                    authCtx.logout();
                }
            }
            console.log(initiateSessionRespo)
        }
    };

    const handleScroll = (direction) => {
        const wrapper = document.getElementById("tabScroller");
        wrapper.scrollBy({ left: direction === "left" ? -150 : 150, behavior: "smooth" });
    };

    const deleteBookingHandler = async (k) => {
        console.log(k)
        setTriggeredBookingTab(k)
        setIsPopupOpen(true)
    }

    return (
        <div className={styles.tabWrapper}>
            {isPopupOpen && (
                <ConfirmationPopup
                    isOpen={isPopupOpen}
                    triggeredBookingTab={triggeredBookingTab}
                    onClose={() => setIsPopupOpen(false)}
                    onDelete={handleDelete}
                    onMarkComplete={handleMarkComplete}
                    isProcessing={isProcessing}
                />
            )}
            <button className={styles.scrollButton} onClick={() => handleScroll("left")}>
                <FaChevronLeft color="#809080" />
            </button>
            <div id="tabScroller" className={styles.tabScroller}>
                {currentSessions?.map((item, index) => (
                    <div className="outerWrapper" key={index}>
                        <div
                            className={
                                item?.bookingDetails?.bookingId === sessionIdentifier
                                    ? styles.activeTab
                                    : styles.tab
                            }
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => handleDrop(index)}
                        >
                            <Link
                                to={`/invoice/create/${item?.bookingDetails?.bookingId}`}
                                className={styles.tabContent}
                            >
                                {item?.bookingDetails?.clientName || item?.bookingDetails?.bookingId}
                            </Link>
                            <MdClose
                                className={styles.closeIcon}
                                onClick={() => {
                                    deleteBookingHandler(item?.bookingDetails?.bookingId)
                                }}
                            />
                        </div>
                    </div>
                ))}
                <button
                    className={styles.addButton}
                    onClick={() => initiateSession()}
                >
                    {bookingInitiationStatus === "INITIATING" ? "Creating..." : <FaPlus />}
                </button>
            </div>
            <button className={styles.scrollButton} onClick={() => handleScroll("right")}>
                <FaChevronRight color="#809080" />
            </button>
        </div>
    )
}

export default GIAlphaTabWrapper