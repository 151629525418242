// import React, { useMemo } from "react";
// import {
//     ResponsiveContainer,
//     AreaChart,
//     Area,
//     XAxis,
//     YAxis,
//     Tooltip,
//     Legend,
//     CartesianGrid,
// } from "recharts";

// const PerformanceOverTime = ({ data }) => {
//     // Process data to group by date and count statuses
//     const chartData = useMemo(() => {
//         const timeCounts = {};

//         data.forEach((item) => {
//             ["acceptedOn", "sentOn", "deliveredOn", "readOn"].forEach((key) => {
//                 if (item[key] !== "0000-00-00 00:00:00") {
//                     const date = item[key].split(" ")[0]; // Extract date (YYYY-MM-DD)

//                     if (!timeCounts[date]) {
//                         timeCounts[date] = { date, accepted: 0, sent: 0, delivered: 0, read: 0 };
//                     }

//                     timeCounts[date][key.replace("On", "")] += 1; // Increment count for the status
//                 }
//             });
//         });

//         return Object.values(timeCounts).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
//     }, [data]);

//     return (
//         <div style={{ width: "100%", height: 400, padding: 20, background: "#fff", borderRadius: 10, boxShadow: "0px 4px 8px rgba(0,0,0,0.1)" }}>
//             <h3 style={{ textAlign: "center", marginBottom: 10 }}>Campaign Performance Over Time</h3>
//             <ResponsiveContainer width="100%" height={350}>
//                 <AreaChart data={chartData}>
//                     <defs>
//                         <linearGradient id="colorAccepted" x1="0" y1="0" x2="0" y2="1">
//                             <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
//                             <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
//                         </linearGradient>
//                         <linearGradient id="colorSent" x1="0" y1="0" x2="0" y2="1">
//                             <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
//                             <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
//                         </linearGradient>
//                         <linearGradient id="colorDelivered" x1="0" y1="0" x2="0" y2="1">
//                             <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
//                             <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
//                         </linearGradient>
//                         <linearGradient id="colorRead" x1="0" y1="0" x2="0" y2="1">
//                             <stop offset="5%" stopColor="#ff0000" stopOpacity={0.8} />
//                             <stop offset="95%" stopColor="#ff0000" stopOpacity={0} />
//                         </linearGradient>
//                     </defs>

//                     <XAxis dataKey="date" stroke="#333" />
//                     <YAxis />
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <Tooltip />
//                     <Legend />

//                     <Area type="monotone" dataKey="accepted" stroke="#8884d8" fillOpacity={1} fill="url(#colorAccepted)" />
//                     <Area type="monotone" dataKey="sent" stroke="#82ca9d" fillOpacity={1} fill="url(#colorSent)" />
//                     <Area type="monotone" dataKey="delivered" stroke="#ff7300" fillOpacity={1} fill="url(#colorDelivered)" />
//                     <Area type="monotone" dataKey="read" stroke="#ff0000" fillOpacity={1} fill="url(#colorRead)" />
//                 </AreaChart>
//             </ResponsiveContainer>
//         </div>
//     );
// };

// export default PerformanceOverTime;

import React, { useMemo } from "react";
import { Chart } from "react-google-charts";
import PerformanceOverTimeInHeatmap from "./PerformanceOverTimeInHeatmap";

const PerformanceOverTime = ({ data }) => {
    // Default data to prevent undefined errors
    const defaultChartData = [["Date", "Accepted", "Sent", "Delivered", "Read"], ["2025-03-17", 0, 0, 0, 0]];

    const chartData = useMemo(() => {
        if (!data || data.length === 0) {
            return defaultChartData; // If no data, return default structure
        }

        const timeCounts = {};

        data.forEach((item) => {
            ["acceptedOn", "sentOn", "deliveredOn", "readOn"].forEach((key) => {
                if (item[key] && item[key] !== "0000-00-00 00:00:00") {
                    const date = item[key].split(" ")[0]; // Extract only the date

                    if (!timeCounts[date]) {
                        timeCounts[date] = [date, 0, 0, 0, 0]; // Initialize row with date
                    }

                    const index = { acceptedOn: 1, sentOn: 2, deliveredOn: 3, readOn: 4 }[key];
                    timeCounts[date][index] += 1; // Increment count for the status
                }
            });
        });

        const sortedData = Object.values(timeCounts).sort((a, b) => new Date(a[0]) - new Date(b[0]));
        return sortedData.length > 0 ? [["Date", "Accepted", "Sent", "Delivered", "Read"], ...sortedData] : defaultChartData;
    }, [data]);

    return (
        <div style={{ width: "100%", padding: "20px", background: "#fff", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0,0,0,0.1)" }}>
            {/* Column Chart */}
            <h3 style={{ textAlign: "center", marginTop: "20px" }}>Daily Message Breakdown</h3>
            <Chart
                width={"100%"}
                height={"350px"}
                chartType="ColumnChart"
                loader={<div>Loading Chart...</div>}
                data={chartData}
                options={{
                    title: "Daily Messages Performance",
                    hAxis: { title: "Date" },
                    vAxis: { title: "Messages Count" },
                    legend: { position: "top" },
                    seriesType: "bars",
                    colors: ["#8884d8", "#82ca9d", "#ff7300", "#ff0000"]
                }}
            />

            <PerformanceOverTimeInHeatmap data={data} />
        </div>
    );
};

export default PerformanceOverTime;
