import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { BASE_URL_API } from "../../../References/URLs";
import ActiveStoreContext from "../../../store/ActiveStoreContext";
import AuthContext from "../../../store/auth-context";
import { FaCheckCircle, FaTimesCircle, FaClock, FaEnvelopeOpen, FaDownload } from "react-icons/fa";

import { motion } from "framer-motion";
import {
    PieChart, Pie, Cell, Tooltip, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, BarChart, Bar
} from "recharts";
import styles from "./CampaignPerformance.module.css";
import PerformanceOverTime from "./PerformanceOverTime";
import { IoMdRefresh } from "react-icons/io";

const COLORS = ["#00C49F", "#FF8042", "#FF0000", "#2816f2", "#FF69B4"]; // Adjusted colors for clarity


const CampaignPerformance = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const { campaignIdentifier } = useParams();

    const [isLoadingRecords, setIsLoadingRecords] = useState(true)
    const [deliveryRecords, setDeliveryRecords] = useState([]);

    const getCampaignDeliveryRecords = async () => {
        setIsLoadingRecords(true)
        const response = await fetch(BASE_URL_API + "getCampaignDeliveryRecords", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                campaignIdentifier,
            }),
        });

        if (!response.ok) {
            console.log("Server Error");
            return;
        }

        const result = await response.json();
        if (result.status === "success") {
            setDeliveryRecords(result.response);
        } else if (result.message === "tokenExpired") {
            authCtx.logout();
        }
        setIsLoadingRecords(false)
    };

    useEffect(() => {
        getCampaignDeliveryRecords();
    }, [campaignIdentifier]);

    const statusCounts = deliveryRecords.reduce(
        (acc, record) => {
            acc[record.currentStatus] = (acc[record.currentStatus] || 0) + 1;
            return acc;
        },
        { accepted: 0, delivered: 0, failed: 0, read: 0, sent: 0 }
    );

    const data = [
        { name: "Accepted", value: statusCounts.accepted },
        { name: "Delivered", value: statusCounts.delivered },
        { name: "Failed", value: statusCounts.failed },
        { name: "Read", value: statusCounts.read },
        { name: "Sent", value: statusCounts.sent },
    ];

    const getStatusIcon = (status) => {
        switch (status) {
            case "delivered":
                return <FaCheckCircle color="#00C49F" title="Delivered" />;
            case "failed":
                return <FaTimesCircle color="#FF0000" title="Failed" />;
            case "read":
                return <FaEnvelopeOpen color="#2816f2" title="Read" />;  // Open envelope for Read
            case "sent":
                return <FaClock color="#FF69B4" title="Sent" />;  // Clock icon for Sent
            default:
                return <FaClock color="#FF8042" title="Pending" />;  // Default pending
        }
    };

    // **Graphical Insights Based on Timestamps**
    const groupedByTime = deliveryRecords.reduce((acc, record) => {
        const timestamp = new Date(record.lastStatusUpdatedOn);
        const hour = timestamp.getHours();
        const formattedTime = `${hour}:00`;

        if (!acc[formattedTime]) {
            acc[formattedTime] = { time: formattedTime, accepted: 0, delivered: 0, failed: 0, read: 0, sent: 0 };
        }
        acc[formattedTime][record.currentStatus] += 1;
        return acc;
    }, {});

    const timeSeriesData = Object.values(groupedByTime).sort((a, b) =>
        parseInt(a.time) - parseInt(b.time)
    );

    return (
        <div className={styles.container}>
            <div className={styles.mainHeader}>
                <div>
                    <div className={styles.title}>Campaign Performance</div>
                </div>
                <div className={styles.headerActionWrapper}>
                    {isLoadingRecords === false ?
                        <button className={`${styles.loadingIcon}`}
                            title="Reload data.."
                            onClick={() => getCampaignDeliveryRecords()}
                        >
                            <IoMdRefresh size={24} />
                        </button>
                        :
                        <button className={`${styles.loadingIconSpin} `}>
                            <IoMdRefresh size={24} className={`${styles.spinner}`} />
                        </button>
                    }
                    <button 
                        className={`${styles.loadingIcon}`}
                        onClick={() => alert(`Downloading performance report is available for admin / premium access only.`)}>
                        <FaDownload />
                    </button>
                </div>
            </div>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={styles.chartContainer}
            >
                <div className={styles.summaryTextWrapper}>
                    <div className={styles.tilesWrapper}>
                        <div className={`${styles.eachStatusSummary} ${styles.readTile}`}  >
                            <div className={styles.essTitle} >Read</div>
                            <div className={styles.essValue} >{data[3]?.value}</div>
                        </div>
                        <div className={styles.eachStatusSummary}>
                            <div className={styles.essTitle} >Delivered</div>
                            <div className={styles.essValue} >{data[1]?.value}</div>
                        </div>
                        <div className={styles.eachStatusSummary}>
                            <div className={styles.essTitle} >Sent</div>
                            <div className={styles.essValue} >{data[4]?.value}</div>
                        </div>
                        <div className={styles.eachStatusSummary}>
                            <div className={styles.essTitle} >Accepted</div>
                            <div className={styles.essValue} >{data[0]?.value}</div>
                        </div>
                        <div className={`${styles.eachStatusSummary} ${styles.failedTile}`}>
                            <div className={styles.essTitle} >Failed</div>
                            <div className={styles.essValue} >{data[2]?.value}</div>
                        </div>
                    </div>
                </div>
                <ResponsiveContainer width="60%" height={350}>
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={80} // Adds a donut effect
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`} // Custom labels
                            isAnimationActive={true}
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    onMouseEnter={(e) => (e.target.style.transform = "scale(1.001)")}
                                    onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                                />
                            ))}
                        </Pie>
                        <Tooltip formatter={(value, name) => [`${value} messages`, name]} />
                        <Legend verticalAlign="bottom" height={36} />
                    </PieChart>
                </ResponsiveContainer>
            </motion.div>


            {/* Line Chart for Message Status Over Time */}
            <div className={styles.chartContainer}>
                <h3>Performance Over Time</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={timeSeriesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="accepted" stroke="#8884d8" />
                        <Line type="monotone" dataKey="delivered" stroke="#00C49F" />
                        <Line type="monotone" dataKey="failed" stroke="#FF0000" />

                        <Line type="monotone" dataKey="read" stroke="#2816f2" />
                        <Line type="monotone" dataKey="sent" stroke="#FF69B4" />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <PerformanceOverTime data={deliveryRecords} />

            {/* Table */}
            <div>
                <div>
                    <input />
                </div>
                <div>
                    <select>
                        <option>All</option>
                        <option>Read</option>
                    </select>
                </div>
            </div>

            <div className={styles.tableContainer}>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
                    {deliveryRecords.map((record, index) => (
                        <motion.div
                            key={index}
                            className={styles.row}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.2, delay: index * 0.005 }}
                        >
                            <div>{record.phoneNumber}</div>
                            <div>{getStatusIcon(record.currentStatus)} {record.currentStatus}</div>
                            <div>{record.createdOn}</div>
                            <div className={styles.smallText}>{record.failedFullResponse}</div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default CampaignPerformance;
