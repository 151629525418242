import React, { useContext, useEffect, useState } from "react";
import NavAtHome from "../../Components/Navigators/NavAtHome";
import styles from "./AllMembers.module.css";
import { Link } from "react-router-dom";
import { BASE_URL_API } from "../../References/URLs";
import AuthContext from "../../store/auth-context";
import ActiveStoreContext from "../../store/ActiveStoreContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import MembershipAnalytics from "../../Components/Membership/MembershipAnalytics";

const AllMembers = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== ""
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();

    const [isLoading, setIsLoading] = useState(true);
    const [cardMembers, setCardMembers] = useState([]);
    const [cardType, setCardType] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [rowsPerPage] = useState(10); // Number of rows per page

    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#D62728"];

    const membershipData = [
        { name: "Active", value: 40 },
        { name: "Expired", value: 30 },
        { name: "Pending", value: 20 },
        { name: "Cancelled", value: 10 },
    ];

    const revenueData = [
        { name: "January", revenue: 5000 },
        { name: "February", revenue: 8000 },
        { name: "March", revenue: 6000 },
        { name: "April", revenue: 10000 },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getAllMembers = async () => {
        setIsLoading(true);
        const response = await fetch(BASE_URL_API + "getAllMembers", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                storeIdentifier: activeStore?.storeIdentifier,
                cardType: cardType,
                searchKey: searchKey,
                currentStatus: currentStatus,
            }),
        });

        if (!response.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const data = await response.json();
            if (data.status === "success") {
                setCardMembers(data.response);
            } else if (data.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setCardMembers([]);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getAllMembers();
    }, [activeStore?.storeIdentifier, cardType, currentStatus, searchKey]);

    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                Please select a store first!
            </div>
        );
    }

    // Pagination logic
    const totalRecords = cardMembers.length;
    const totalPages = Math.ceil(totalRecords / rowsPerPage);
    const displayedMembers = cardMembers.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const isExpired = (expiryDate) => {
        if (!expiryDate || typeof expiryDate !== "string") {
            return false; // Return false if expiryDate is null, empty, or undefined
        }
    
        const expiry = new Date(expiryDate);
        
        if (isNaN(expiry.getTime())) {
            return false; // Return false if expiryDate is invalid
        }
    
        if(new Date() > expiry) {
            return <span className={styles.expired}>Expired</span>
        } else {
            return <span className={styles.active}>Active</span>
        }
    }


    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    className={`${styles.pageButton} ${i === currentPage ? styles.active : ""}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.pageTitle}>
                    All Membership / Gift Cards
                </div>
                <div className={styles.mhRight}>
                    <Link to={`/memberships/sell`}>
                        Create New
                    </Link>
                </div>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.leftCol}>
                    <div className={styles.headerWrapper}>
                        <div className={styles.optionsWrapper} >
                            <div className={styles.inputWrapper}>
                                <input
                                    type="text"
                                    placeholder="Search for any member..."
                                    onChange={(e) => setSearchKey(e.target.value)}
                                    value={searchKey}
                                />
                            </div>
                            <div className={styles.filters}>
                                <select onChange={(e) => setCurrentStatus(e.target.value)}>
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Expired">Expired</option>
                                </select>
                                <select onChange={(e) => setCardType(e.target.value)}>
                                    <option value="">Select Membership Type</option>
                                    <option value="SUPERSAVER">SUPERSAVER</option>
                                    <option value="GALLANT">GALLANT</option>
                                    <option value="SILVER">SILVER</option>
                                    <option value="GOLD">GOLD</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className={styles.loader}>
                            <AiOutlineLoading3Quarters className={styles.spinner} />
                        </div>
                    ) : (
                        <div className={styles.tableWrapper}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Card Number</th>
                                        <th>Type</th>
                                        <th>Client Name</th>
                                        <th>Phone</th>
                                        <th>Worth</th>
                                        <th>Paid</th>
                                        <th>Purchased On</th>
                                        <th>Expiring On</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedMembers.map((member, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                                            <td>
                                                <Link to={`/membership/${member?.issuedCardNumber}`}>
                                                    {member.issuedCardNumber}
                                                </Link>
                                            </td>
                                            <td>{member.cardLabel}</td>
                                            <td>{member.client_name}
                                            </td>
                                            <td>{member.mobile}</td>
                                            <td>{member.worth}</td>
                                            <td>{member.amountPaid}</td>
                                            <td>{member.issuedOn}</td>
                                            <td>{member.expiryDate}</td>
                                            <td>{isExpired(member.expiryDate)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={styles.pagination}>{renderPagination()}</div>
                        </div>
                    )}
                </div>

                <div className={styles.rightCol}>
                    <MembershipAnalytics data={cardMembers} />
                </div>
            </div>
        </div>
    );
};

export default AllMembers;
