import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import styles from './CreateCampaignPopup.module.css';
import { BASE_URL_API } from '../../../References/URLs';
import AuthContext from '../../../store/auth-context';
import ActiveStoreContext from '../../../store/ActiveStoreContext';

const CreateCampaignPopup = (props) => {
    const { isOpen, onClose, onSuccess } = props
    const [campaignName, setCampaignName] = useState('')
    const [campaignDescription, setCampaignDescription] = useState('')
    const popupRef = useRef(null)
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => {try {return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null;} catch { return null;}})();



    const handleEscapeKey = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            onClose();
        }
    };

    const handleSubmit = async () => {
        if (!campaignName.trim()) {
            alert('Campaign name cannot be empty.');
        } else if (campaignName.length > 80) {
            alert('Campaign name cannot exceed 80 characters.');
        } else {
            const createANewCampaign = await fetch(BASE_URL_API+"createANewCampaign",
            {
                method : "POST",
                body : JSON.stringify({
                    token : authCtx.token,
                    storeIdentifier : activeStore.storeIdentifier,
                    campaignName : campaignName,
                    campaignDescription : campaignDescription,
                    createdBy : authCtx.authIdentifier
                })
            });

            if(!createANewCampaign.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const createANewCampaignRespo = await createANewCampaign.json()
                if(createANewCampaignRespo?.status==="success") {
                    onSuccess(createANewCampaignRespo?.response);
                } else {
                    if(createANewCampaignRespo.message==="tokenExpired") {
                        authCtx.logout()
                    }
                }
                console.log(createANewCampaignRespo?.response)
            }

        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleEscapeKey);
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('keydown', handleEscapeKey);
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div>
            {/* <button className={styles.createNewCampaign} onClick={openPopup}>
                +
            </button> */}

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.popupOverlay}
                    >
                        <motion.div
                            ref={popupRef}
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.3, type: 'spring' }}
                            className={styles.popupContainer}
                        >
                            <div className={styles.popupHeader}>
                                <div className={styles.popupTitle}>Create New Campaign</div>
                                <div
                                    onClick={onClose}
                                    className={styles.closeButton}
                                >
                                    <X size={24} />
                                </div>
                            </div>

                            <div className={styles.eachFieldWrapper}>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Campaign Name
                                </label>
                                <input
                                    type="text"
                                    value={campaignName}
                                    onChange={(e) => {
                                        setCampaignName(e.target.value);
                                    }}
                                    className={styles.inputField}
                                    placeholder="Enter campaign name"
                                />

                            </div>

                            <button
                                onClick={handleSubmit}
                                className={styles.submitButton}
                            >
                                Create Campaign
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CreateCampaignPopup;
