export const audienceData2 = [
    // Demographics
    {
        audienceIdentifier: "dem_all_males",
        audienceName: "All Male Users",
        audienceDescription: "Registered male users from all regions.",
        category: "Demographics",
        subcategory: "Gender",
        audienceCriteria: {
            gender: "Male",
        },
        audienceData: [
            { phoneNumber: "919999648406", clientName: "Navneet Chandan", gender: "Male", age: 30, city: "Delhi" },
            { phoneNumber: "919888648406", clientName: "Amit Gupta", gender: "Male", age: 25, city: "Mumbai" },
        ],
        isSelected: false,
    },
    {
        audienceIdentifier: "dem_age_18_25",
        audienceName: "Users Aged 18-25",
        audienceDescription: "Users aged between 18 and 25.",
        category: "Demographics",
        subcategory: "Age",
        audienceCriteria: {
            ageRange: [18, 25],
        },
        audienceData: [
            { phoneNumber: "919777648406", clientName: "Annu Singh", gender: "Female", age: 22, city: "Noida" },
            { phoneNumber: "919666648406", clientName: "Rahul Mehra", gender: "Male", age: 24, city: "Chandigarh" },
        ],
        isSelected: false,
    },

    // Behavioral
    {
        audienceIdentifier: "beh_high_engagers",
        audienceName: "High Engagers",
        audienceDescription: "Users with high engagement in the past 30 days.",
        category: "Behavioral",
        subcategory: "Engagement",
        audienceCriteria: {
            engagementScore: ">80%",
            lastActive: "30 days",
        },
        audienceData: [
            { phoneNumber: "919555648406", clientName: "Priya Sharma", gender: "Female", engagementScore: 85, city: "Pune" },
        ],
        isSelected: false,
    },
    {
        audienceIdentifier: "beh_churned_users",
        audienceName: "Churned Users",
        audienceDescription: "Users who haven’t interacted in the last 90 days.",
        category: "Behavioral",
        subcategory: "Inactivity",
        audienceCriteria: {
            lastActive: ">90 days",
        },
        audienceData: [
            { phoneNumber: "919444648406", clientName: "Karan Khanna", gender: "Male", lastActive: 120, city: "Jaipur" },
        ],
        isSelected: false,
    },

    // Transactional
    {
        audienceIdentifier: "txn_high_spenders",
        audienceName: "High Spenders",
        audienceDescription: "Users who have spent over $500 in the last 6 months.",
        category: "Transactional",
        subcategory: "Spending",
        audienceCriteria: {
            spend: ">500",
            timeframe: "6 months",
        },
        audienceData: [
            { phoneNumber: "919333648406", clientName: "Manisha Verma", gender: "Female", spend: 600, city: "Bangalore" },
        ],
        isSelected: false,
    },
    {
        audienceIdentifier: "txn_new_buyers",
        audienceName: "New Buyers",
        audienceDescription: "Users who made their first purchase in the last 30 days.",
        category: "Transactional",
        subcategory: "Recency",
        audienceCriteria: {
            firstPurchase: "30 days",
        },
        audienceData: [
            { phoneNumber: "919222648406", clientName: "Vikram Singh", gender: "Male", firstPurchaseDate: "2024-12-01", city: "Hyderabad" },
        ],
        isSelected: false,
    },

    // Geographic
    {
        audienceIdentifier: "geo_city_delhi",
        audienceName: "Users in Delhi",
        audienceDescription: "Users located in Delhi region.",
        category: "Geographic",
        subcategory: "City",
        audienceCriteria: {
            city: "Delhi",
        },
        audienceData: [
            { phoneNumber: "919111648406", clientName: "Rohit Kapoor", gender: "Male", city: "Delhi", age: 29 },
        ],
        isSelected: false,
    },
    {
        audienceIdentifier: "geo_region_south",
        audienceName: "South Region Users",
        audienceDescription: "Users from South Indian states.",
        category: "Geographic",
        subcategory: "Region",
        audienceCriteria: {
            region: ["Karnataka", "Tamil Nadu", "Kerala", "Andhra Pradesh"],
        },
        audienceData: [
            { phoneNumber: "919000648406", clientName: "Divya Menon", gender: "Female", city: "Chennai", state: "Tamil Nadu" },
        ],
        isSelected: false,
    },

    // Other Custom Segments
    {
        audienceIdentifier: "custom_loyalty_program",
        audienceName: "Loyalty Program Members",
        audienceDescription: "Users enrolled in the loyalty program.",
        category: "Custom",
        subcategory: "Membership",
        audienceCriteria: {
            loyaltyProgram: true,
        },
        audienceData: [
            { phoneNumber: "918888648406", clientName: "Arjun Malik", gender: "Male", loyaltyTier: "Gold", city: "Lucknow" },
        ],
        isSelected: false,
    },
    {
        audienceIdentifier: "custom_event_attendees",
        audienceName: "Event Attendees",
        audienceDescription: "Users who attended the latest events.",
        category: "Custom",
        subcategory: "Event",
        audienceCriteria: {
            attendedEvent: "Annual Gala 2024",
        },
        audienceData: [
            { phoneNumber: "918777648406", clientName: "Simran Kaur", gender: "Female", city: "Amritsar" },
        ],
        isSelected: false,
    },
];



export const audienceData = [
    {
        "category": "Demographics",
        "audiences": [
            {
                "audienceIdentifier": "demo_001",
                "audienceName": "Young Adults",
                "audienceDescription": "Users aged 18-25",
                "criteria": { "ageRange": "18-25" }
            },
            {
                "audienceIdentifier": "demo_002",
                "audienceName": "Male Shoppers",
                "audienceDescription": "All your male clients",
                "criteria": { "ageRange": "26-45", "occupation": "Professional" }
            },
            {
                "audienceIdentifier": "demo_003",
                "audienceName": "Female Shoppers",
                "audienceDescription": "Women interested in shopping",
                "criteria": { "gender": "Female", "interest": "Shopping" }
            },
            {
                "audienceIdentifier": "demo_004",
                "audienceName": "High-Income Group",
                "audienceDescription": "Users with high annual income",
                "criteria": { "incomeLevel": "High" }
            },
            {
                "audienceIdentifier": "demo_005",
                "audienceName": "Low-Income Group",
                "audienceDescription": "Users with low annual income",
                "criteria": { "incomeLevel": "Low" }
            },
            {
                "audienceIdentifier": "demo_006",
                "audienceName": "Losing Customers Group",
                "audienceDescription": "Users with low annual income",
                "criteria": { "incomeLevel": "Low" }
            }
        ]
    },
    {
        "category": "Behavioral",
        "audiences": [
            {
                "audienceIdentifier": "behav_001",
                "audienceName": "Frequent Buyers",
                "audienceDescription": "Users who purchase regularly",
                "criteria": { "purchaseFrequency": "High" }
            },
            {
                "audienceIdentifier": "behav_002",
                "audienceName": "Dormant Users",
                "audienceDescription": "Users with no recent activity",
                "criteria": { "lastActivity": "More than 6 months ago" }
            },
            {
                "audienceIdentifier": "behav_003",
                "audienceName": "Cart Abandoners",
                "audienceDescription": "Users who abandoned their cart",
                "criteria": { "cartStatus": "Abandoned" }
            }
        ]
    },
    {
        "category": "Geographic",
        "audiences": [
            {
                "audienceIdentifier": "geo_001",
                "audienceName": "Urban Residents",
                "audienceDescription": "Users living in urban areas",
                "criteria": { "locationType": "Urban" }
            },
            {
                "audienceIdentifier": "geo_002",
                "audienceName": "Users in New York",
                "audienceDescription": "Users located in New York City",
                "criteria": { "city": "New York" }
            },
            {
                "audienceIdentifier": "geo_003",
                "audienceName": "Proximity Campaign Audience",
                "audienceDescription": "Users within 10km of a store",
                "criteria": { "distanceFromStore": "10km" }
            }
        ]
    },
    {
        "category": "Psychographics",
        "audiences": [
            {
                "audienceIdentifier": "psycho_001",
                "audienceName": "Fitness Enthusiasts",
                "audienceDescription": "Users interested in fitness",
                "criteria": { "interest": "Fitness" }
            },
            {
                "audienceIdentifier": "psycho_002",
                "audienceName": "Eco-Conscious",
                "audienceDescription": "Environmentally conscious users",
                "criteria": { "values": "Sustainability" }
            },
            {
                "audienceIdentifier": "psycho_003",
                "audienceName": "Luxury Seekers",
                "audienceDescription": "Users with a preference for luxury products",
                "criteria": { "preference": "Luxury" }
            }
        ]
    },
    {
        "category": "Technology",
        "audiences": [
            {
                "audienceIdentifier": "tech_001",
                "audienceName": "iPhone Users",
                "audienceDescription": "Users accessing via iPhone",
                "criteria": { "deviceType": "iPhone" }
            },
            {
                "audienceIdentifier": "tech_002",
                "audienceName": "Desktop Users",
                "audienceDescription": "Users accessing via desktop devices",
                "criteria": { "deviceType": "Desktop" }
            },
            {
                "audienceIdentifier": "tech_003",
                "audienceName": "Android Users",
                "audienceDescription": "Users accessing via Android devices",
                "criteria": { "deviceType": "Android" }
            }
        ]
    },
    {
        "category": "Interaction History",
        "audiences": [
            {
                "audienceIdentifier": "history_001",
                "audienceName": "Recent Email Openers",
                "audienceDescription": "Users who opened the last email campaign",
                "criteria": { "emailStatus": "Opened" }
            },
            {
                "audienceIdentifier": "history_002",
                "audienceName": "Ad Clickers",
                "audienceDescription": "Users who clicked on specific ads",
                "criteria": { "interaction": "Ad Click" }
            },
            {
                "audienceIdentifier": "history_003",
                "audienceName": "Engaged Social Media Followers",
                "audienceDescription": "Users highly active on social media",
                "criteria": { "socialEngagement": "High" }
            }
        ]
    },
    {
        "category": "Custom Tags",
        "audiences": [
            {
                "audienceIdentifier": "custom_001",
                "audienceName": "VIP Customers",
                "audienceDescription": "High-value, loyal customers",
                "criteria": { "customerType": "VIP" }
            },
            {
                "audienceIdentifier": "custom_002",
                "audienceName": "First-Time Buyers",
                "audienceDescription": "Customers making their first purchase",
                "criteria": { "purchaseStatus": "First-Time" }
            }
        ]
    },
    {
        "category": "Event-Based",
        "audiences": [
            {
                "audienceIdentifier": "event_001",
                "audienceName": "Webinar Attendees",
                "audienceDescription": "Users who attended recent webinars",
                "criteria": { "eventType": "Webinar", "attendance": "Yes" }
            },
            {
                "audienceIdentifier": "event_002",
                "audienceName": "Holiday Shoppers",
                "audienceDescription": "Users active during holiday sales",
                "criteria": { "season": "Holiday", "activity": "High" }
            }
        ]
    }
]
