import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaFilter, FaSort, FaSearch } from "react-icons/fa";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar } from "recharts";
import styles from "./ManageEachStaffWorkBookingData.module.css";
import Chart from "react-google-charts";

const ManageEachStaffWorkBookingData = ({ workDetails, dates }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc");
    const [selectedService, setSelectedService] = useState("All");
    const [selectedDate, setSelectedDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [weeklyData, setWeeklyData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [weeklyWorkData, setWeeklyWorkData] = useState([]);

    useEffect(() => {
        if (Array.isArray(workDetails?.dailyTransData?.[0]?.allServices)) {
            const services = workDetails.dailyTransData[0].allServices;
            setFilteredData(services);
            processWeeklyAndMonthlyData(services);
            processWeeklyWorkData(services);
        } else {
            setFilteredData([]);
            setWeeklyData([]);
            setMonthlyData([]);
            setWeeklyWorkData([]);
        }
    }, [workDetails]);

    const processWeeklyAndMonthlyData = (data) => {
        const weekMap = new Map();
        const monthMap = new Map();

        data.forEach((entry) => {
            const date = new Date(entry.date);
            const week = `${date.getFullYear()}-W${Math.ceil(date.getDate() / 7)}`;
            const month = `${date.getFullYear()}-${date.getMonth() + 1}`;

            if (!weekMap.has(week)) {
                weekMap.set(week, { week, revenue: 0, count: 0 });
            }
            if (!monthMap.has(month)) {
                monthMap.set(month, { month, revenue: 0, count: 0 });
            }

            weekMap.get(week).revenue += entry.totalDiscPrice;
            weekMap.get(week).count += 1;
            monthMap.get(month).revenue += entry.totalDiscPrice;
            monthMap.get(month).count += 1;
        });

        setWeeklyData(Array.from(weekMap.values()));
        setMonthlyData(Array.from(monthMap.values()));
        // console.log(data)
        // console.log(weekMap, monthMap)
    };

    const processWeeklyWorkData = (data) => {
        const weeklyWorkMap = new Map();

        data.forEach((entry) => {
            const date = new Date(entry.date);
            const week = `${date.getFullYear()}-W${Math.ceil(date.getDate() / 7)}`;

            if (!weeklyWorkMap.has(week)) {
                weeklyWorkMap.set(week, { week, totalWork: 0 });
            }

            weeklyWorkMap.get(week).totalWork += entry.completedWork;
        });

        setWeeklyWorkData(Array.from(weeklyWorkMap.values()));
    };

    const getDailyTotals = (allServices, startDate, endDate) => {
        // Create a map of all dates within the range and initialize them with 0
        const dateMap = {};
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
            const formattedDate = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            dateMap[formattedDate] = 0;
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        // Aggregate amounts by date
        allServices.forEach(service => {
            const date = service.addedOn.split(' ')[0]; // Extract only the date part
            const amount = parseFloat(service.totalDiscPrice) || 0; // Convert to number, fallback to 0

            if (dateMap[date] !== undefined) {
                dateMap[date] += amount;
            }
        });

        // Convert to array format
        return Object.entries(dateMap).map(([date, amount]) => ({ date, amount }));
    };

    const dailyTotals = getDailyTotals(workDetails?.dailyTransData?.[0]?.allServices, '2025-01-01', '2025-03-05');
    // console.log(dailyTotals);

    const getWeeklyTotals = (allServices, startDate, endDate) => {
        const weekMap = {};
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        // Initialize all weeks within the range with 0
        while (currentDate <= end) {
            const weekStart = new Date(currentDate);
            weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Get Sunday of that week
            const weekKey = weekStart.toISOString().split('T')[0]; // Format as YYYY-MM-DD

            if (!weekMap[weekKey]) {
                weekMap[weekKey] = 0;
            }

            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        // Aggregate amounts by week
        allServices.forEach(service => {
            const date = new Date(service.addedOn.split(' ')[0]); // Extract only the date part
            const weekStart = new Date(date);
            weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Get Sunday of that week
            const weekKey = weekStart.toISOString().split('T')[0]; // Format as YYYY-MM-DD

            const amount = parseFloat(service.totalDiscPrice) || 0; // Convert to number, fallback to 0

            if (weekMap[weekKey] !== undefined) {
                weekMap[weekKey] += amount;
            }
        });

        // Convert to array format
        return Object.entries(weekMap).map(([weekStart, amount]) => ({ weekStart, amount }));
    };


    const getMonthlyTotals = (allServices, startDate, endDate) => {
        const monthMap = {};
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        // Initialize all months within the range with 0
        while (currentDate <= end) {
            const monthKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;

            if (!monthMap[monthKey]) {
                monthMap[monthKey] = 0;
            }

            currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
        }

        // Aggregate amounts by month
        allServices.forEach(service => {
            const date = new Date(service.addedOn.split(' ')[0]); // Extract only the date part
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

            const amount = parseFloat(service.totalDiscPrice) || 0; // Convert to number, fallback to 0

            if (monthMap[monthKey] !== undefined) {
                monthMap[monthKey] += amount;
            }
        });

        // Convert to array format
        return Object.entries(monthMap).map(([month, amount]) => ({ month, amount }));
    };





    return (
        <div className={styles.container}>
            <h2 className={styles.dashboardTitle}>Staff Work Booking Dashboard</h2>

            {/* <div className={styles.graphsContainer}>
                <ResponsiveContainer width="48%" height={300}>
                    <LineChart data={filteredData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="addedOn" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="totalDiscPrice" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
                <ResponsiveContainer width="48%" height={300}>
                    <BarChart data={filteredData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="serviceType" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="totalDiscPrice" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div> */}

            <div className={styles.graphsWrapper}>
                <div className={styles.graphsContainer}>
                    <div className={styles.chartTitle}>
                        Daily
                    </div>
                    <ResponsiveContainer width="98%" height={360}>
                        <BarChart data={getDailyTotals(workDetails?.dailyTransData?.[0]?.allServices, dates?.startDate, dates?.endDate)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="amount" fill="#3498db" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div className={styles.graphsContainer}>
                    <div className={styles.chartTitle}>
                        Weekly
                    </div>
                    <ResponsiveContainer width="98%" height={300}>
                        <LineChart data={getWeeklyTotals(workDetails?.dailyTransData?.[0]?.allServices, dates?.startDate, dates?.endDate)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="weekStart" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="amount" stroke="#ff7300" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className={styles.graphsContainer}>
                    <div className={styles.chartTitle}>
                        Monthly
                    </div>
                    <ResponsiveContainer width="98%" height={300}>
                        <LineChart data={getMonthlyTotals(workDetails?.dailyTransData?.[0]?.allServices, dates?.startDate, dates?.endDate)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="amount" stroke="#387908" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className={styles.graphsContainer}>
                    <div className={styles.chartTitle}>
                        Daily
                    </div>
                    <div className={styles.chartHolder} >

                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="360px"
                        data={
                            [
                                ["Date", "Total Amount"], // Header
                                ...getDailyTotals(workDetails?.dailyTransData?.[0]?.allServices, dates?.startDate, dates?.endDate).map(({ date, amount }) => [date, amount]),
                            ]
                        }
                        options={{
                            title: "Daily Transactions",
                            legend: { position: "none" },
                            hAxis: { title: "Date", slantedText: true, slantedTextAngle: 45 },
                            vAxis: { title: "Amount" },
                            chartArea: { width: "80%", height: "70%" },
                            colors: ["#3498db"],
                            curveType: "function"
                        }}
                        />
                        </div>
                </div>

                <div className={styles.tableContainer}>
                    <table className={styles.dataTable}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Service Type</th>
                                <th>Revenue (₹)</th>
                                <th>Completed Work</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((staff, index) => (
                                    <tr key={index}>
                                        <td>{staff.addedOn?.substring(0, 10)}</td>
                                        <td>{staff.serviceName}</td>
                                        <td>₹{staff.totalDiscPrice.toLocaleString()}</td>
                                        <td>{staff.servedByContribution}%</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className={styles.noData}>No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default ManageEachStaffWorkBookingData;
