import React, { useContext, useState } from 'react'
import styles from './AddSectionPopUp.module.css'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { FaSpinner } from 'react-icons/fa';

const AddSectionPopUp = (props) => {
    const { onClose, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [sectionTitle, setSectionTitle] = useState('')
    const [sectionNotes, setSectionNotes] = useState('')
    const [isAdding, setIsAdding] = useState(false)

    const createAServicesSection = async () => {
        if (sectionTitle !== '') {
            setIsAdding(true)
            const createAServicesSectionResponse = await fetch(BASE_URL_API + "createAServicesSection",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        storeIdentifier: activeStore?.storeIdentifier,
                        section: sectionTitle,
                        sectionNotes: sectionNotes,
                        vendorIdentifier: JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier
                    })
                });

            if (!createAServicesSectionResponse.ok) {
                console.log(`Something went wrong : Server Error`)
            } else {
                const createAServicesSectionRespo = await createAServicesSectionResponse.json()
                if (createAServicesSectionRespo.status === 'success') {
                    onSuccess()
                } else {
                    if (createAServicesSectionRespo?.message === 'tokenExpired') {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(createAServicesSectionRespo)
            }
            setIsAdding(false)
        } else {
            alert(`Title cannot be empty!`)
        }

    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.sectionTitle}>
                        Create A New Section
                    </div>
                    <div className={styles.sectionBody}>
                        <div className={styles.inputTitle}>
                            <input placeholder="Add Section title" autoFocus={true}
                                onChange={(e) => setSectionTitle(e.target.value)}
                                value={sectionTitle}
                            />
                        </div>
                        <div className={styles.inputNotes}>
                            <textarea className={styles.notes} rows={4}
                                placeholder="You can add notes for your reference"
                                onChange={(e) => setSectionNotes(e.target.value)}
                                value={sectionNotes}
                            >
                            </textarea>
                        </div>
                    </div>
                    <div className={styles.sectionFooter}>
                    <button className={styles.cancelBtn} onClick={() => onClose()}>Close</button>
                        {isAdding === true ?
                            <button className={styles.addBtn} >
                                <FaSpinner className={styles.spinner} /> Saving
                            </button>
                            :
                            <button className={styles.addBtn}
                                onClick={() => createAServicesSection()}
                            >
                                Create
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSectionPopUp