import React, { useContext, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachServicesCategory.module.css';
import AddNewService from './AddNewService';
import EachAddedService from './EachAddedService';

const EachServicesCategory = props => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const { item, selectServiceHandler, onDeletion, sections, activeSection } = props
    const storeIdentifier = activeStore?.storeIdentifier
    let categoryIdentifier = item.categoryIdentifier
    const [isExpanded, setIsExpanded] = useState(false)
    const [addedServices, setAddedServices] = useState(null)
    const [isDeletingCategory, setIsDeletingCategory] = useState(false)

    const getServices = async () => {
        const getServicesResponse = await fetch(BASE_URL_API + "getServices",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: storeIdentifier,
                    categoryIdentifier: categoryIdentifier
                })
            });

        if (!getServicesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getServicesRespo = await getServicesResponse.json();
            if (getServicesRespo.status == "success") {
                setAddedServices(getServicesRespo.response)
            } else {
                setAddedServices(null)
                // console.log(getServicesRespo.response);
            }
        }
    }

    useEffect(() => {
        getServices();
    }, [storeIdentifier])

    // console.log("This is added services : ", addedServices);

    const removeCategory = async () => {
        setIsDeletingCategory(true)
        const removeCategoryResponse = await fetch(BASE_URL_API + "removeCategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    categoryIdentifier: item?.categoryIdentifier
                })
            });

        if (!removeCategoryResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const removeCategoryRespo = await removeCategoryResponse.json()
            if (removeCategoryRespo.status === 'success') {
                toast(`Successfully deleted the category!`)
                onDeletion()
            } else {
                if (removeCategoryRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(removeCategoryRespo)
        }
        setIsDeletingCategory(false)
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.categoryHeader} onClick={() => setIsExpanded(!isExpanded)}>
                <div className={styles.categoryTitle}>{item.categoryLabel}</div>
                <div >{isExpanded === true ? <FaChevronUp /> : <FaChevronDown />}</div>
            </div>
            {isExpanded &&
                <div className={styles.categoryBody}>
                    {addedServices !== null ?
                        <div className={styles.addedServicesBody}>
                            {addedServices.map((itemAdded, index) => (
                                <EachAddedService
                                    key={index}
                                    item={itemAdded}
                                    selectServiceHandler={selectServiceHandler}
                                    getServices={getServices}
                                    sections={sections}
                                    activeSection={activeSection}
                                />
                            ))}
                        </div>
                        :
                        <div className={styles.noResultsFound}>
                            No services added for this category!
                        </div>
                    }
                    <div>
                        <AddNewService
                            storeIdentifier={storeIdentifier}
                            categoryIdentifier={categoryIdentifier}
                            categoryName={item.categoryLabel}
                            genderIdentifier={item.genderIdentifier}
                            getServices={getServices}
                            activeSection={activeSection}
                        />
                    </div>
                    <div className={styles.deleteCategoryWrapper}>
                        {isDeletingCategory === true ?
                            <div className={styles.deletingCategoryContainer}>
                                <button > <MdDelete size={18} /> {`Deleting ${item?.categoryLabel} Category...`}</button>
                            </div>
                            :
                            <div className={styles.deleteCategoryContainer}>
                                <button onClick={() => removeCategory(item)}> <MdDelete size={18} /> {`Delete ${item?.categoryLabel} Category`}</button>
                            </div>
                        }
                    </div>

                </div>
            }
        </div>
    )
}

export default EachServicesCategory