import React, { useContext, useEffect, useState } from "react";
import styles from "./StoreEmployees.module.css";
import StoresHeader from "../../Components/Stores/StoresHeader";
import AddNewStaff from "../../Components/StaffManagement/AddNewStaff";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL_API } from "../../References/URLs";
import AuthContext from "../../store/auth-context";
import ActiveStoreContext from "../../store/ActiveStoreContext";
import { Link } from "react-router-dom";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const StoreEmployees = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = JSON.parse(asCtx?.activeStore);

    const [isLoading, setIsLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [isAddingNew, setIsAddingNew] = useState(false);

    useEffect(() => {
        if (activeStore) fetchEmployees();
    }, [activeStore?.storeIdentifier]);

    const fetchEmployees = async () => {
        try {
            const response = await fetch(`${BASE_URL_API}getWorkersData`, {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                }),
            });

            if (!response.ok) throw new Error("Server Error");

            const data = await response.json();
            if (data.status === "success") {
                setEmployees(data.response);
            } else if (data.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setEmployees([]);
            }
        } catch (error) {
            console.error("Failed to fetch employees:", error);
        }
    };

    const toggleCalendarAvailability = async (employeeId) => {
        setCurrentItem(employeeId);
        setIsLoading(true);

        try {
            const response = await fetch(`${BASE_URL_API}toggleCalendarAvailablility`, {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    worker_id: employeeId,
                }),
            });

            if (!response.ok) throw new Error("Server Error");

            const data = await response.json();
            if (data.status === "success") {
                toast("Successfully updated!");
                fetchEmployees();
            } else if (data.message === "tokenExpired") {
                authCtx.logout();
            }
        } catch (error) {
            console.error("Failed to toggle availability:", error);
        } finally {
            setIsLoading(false);
            setCurrentItem(null);
        }
    };

    if (!activeStore) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>Please select a store!</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.mainWrapper}>
            <StoresHeader />
            <ToastContainer />
            <AddNewStaff
                isOpen={isAddingNew}
                onClose={() => setIsAddingNew(false)}
                onSuccess={() => {
                    toast("Successfully created!");
                    setIsAddingNew(false);
                    fetchEmployees();
                }}
            />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div>
                            <div className={styles.title}>Store Employees</div>
                            <div className={styles.subTitle}>All details about your store employees.</div>
                        </div>
                        <div className={styles.addNew}>
                            <button onClick={() => setIsAddingNew(true)}>
                                Add A New Employee
                            </button>
                        </div>
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.name}>Name</div>
                                <div className={styles.email}>E-mail</div>
                                <div className={styles.phone}>Phone</div>
                                <div className={styles.role}>Role</div>
                                <div className={styles.cal}>Calendar Bookings</div>
                                <div className={styles.actions}>Actions</div>
                            </div>
                            <div className={styles.tableBody}>
                                {employees.length > 0 ? (
                                    employees.map((employee) => (
                                        <div key={employee.id} className={styles.eachRowWrapper}>
                                            <div className={styles.name}>{employee.name}</div>
                                            <div className={styles.email}>{employee.email}</div>
                                            <div className={styles.phone}>{employee.mobile}</div>
                                            <div className={styles.role}>{employee.role}</div>
                                            <div
                                                className={styles.cal}
                                                onClick={() => toggleCalendarAvailability(employee.id)}
                                            >
                                                {isLoading && currentItem === employee.id ? (
                                                    <FaSpinner size={18} className={styles.spinner} />
                                                ) : employee.isAvailableForCalendar === "0" ? (
                                                    <BsToggleOff size={24} />
                                                ) : (
                                                    <BsToggleOn size={24} color="#027fff" />
                                                )}
                                            </div>
                                            <div className={styles.actions}>
                                                <Link to={`/store/employee/${employee.id}`}>Manage Employee</Link>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className={styles.noData}>No employees found.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreEmployees;
