import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import AuthContext from '../../store/auth-context'
import styles from './ManageEachStaffWork.module.css'
import NumericCounter from '../UI/NumericCounter'
import ManageEachStaffWorkBookingData from './ManageEachStaffWorkBookingData'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import { Cell, PieChart, ResponsiveContainer } from 'recharts'
import { Pie } from 'react-chartjs-2'
import { Legend, Tooltip } from 'chart.js'
import Chart from 'react-google-charts'

const ManageEachStaffWork = (props) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const { dates, selectedStaff } = props
    const { staffIdentifier } = useParams()

    const [isFetchingIncentive, setIsFetchingIncentive] = useState(true)

    const [workDetails, setWorkDetails] = useState(null)
    const [salary, setSalary] = useState(31000)
    const [stateValue, setStateValue] = useState(0)



    const getStaffIncentive = async () => {
        setIsFetchingIncentive(true)
        const getStaffIncentiveResponse = await fetch(BASE_URL_API + "getStaffIncentive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    staffIdentifier: staffIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getStaffIncentiveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffIncentiveRespo = await getStaffIncentiveResponse.json()
            if (getStaffIncentiveRespo.status === 'success') {
                setWorkDetails(getStaffIncentiveRespo.response)
            } else {
                if (getStaffIncentiveRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setWorkDetails(null)
                }
                console.log(getStaffIncentiveRespo)
            }
        }
        setIsFetchingIncentive(false)
    }



    useEffect(() => {
        setStateValue(0)
        if (staffIdentifier !== undefined) {
            getStaffIncentive().then(() => setStateValue(workDetails?.totalAmount))
        }
    }, [staffIdentifier, dates])


    const incentiveCalculator = () => {
        let incentive = 0;
        const factor = (workDetails?.totalAmount) / salary;
        if (parseInt(factor) === 2) {
            incentive = ((workDetails?.totalAmount) * (5 / 100));
        } else if (parseInt(factor) === 3) {
            incentive = ((workDetails?.totalAmount) * (7 / 100));
        } else if (parseInt(factor) === 4) {
            incentive = ((workDetails?.totalAmount) * (10 / 100));
        } else if (parseInt(factor) > 4) {
            incentive = ((workDetails?.totalAmount) * (10 / 100));
        } else {
            incentive = 0;
        }
        console.log(incentive, factor, parseInt(factor))
        // if()
    }


    useEffect(() => {
        incentiveCalculator()
    }, [workDetails])


    // Ensure allServices is defined and is an array
    if (!Array.isArray(workDetails?.dailyTransData?.[0]?.allServices) || workDetails?.dailyTransData?.[0]?.allServices?.length === 0) {
        return (
            <div className={styles.bodyWrapper}>
                <div className={styles.noWorkLeft}>
                    <div>
                        No work found!
                    </div>
                </div>
                <div className={styles.noworkIcon} >
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/LokaciPro/project-assets/try-changing-dates.png" />
                </div>
            </div>
        )
    }

    // Process data using categoryIdentifier
    const categoryData = workDetails?.dailyTransData?.[0]?.allServices?.reduce((acc, service) => {
        if (!service?.categoryIdentifier || !service?.categoryName) return acc;

        const { categoryIdentifier, categoryName } = service;
        if (!acc[categoryIdentifier]) {
            acc[categoryIdentifier] = { name: categoryName, value: 1 };
        } else {
            acc[categoryIdentifier].value += 1;
        }
        return acc;
    }, {});

    // Convert object to array format for Google Charts
    const chartData = [["Category", "Count"], ...Object.values(categoryData).map(({ name, value }) => [name, value])];




    // Define colors for categories
    const COLORS = ["#3498db", "#e74c3c", "#2ecc71", "#f1c40f", "#9b59b6", "#34495e"];




    const categoryRevenueData = workDetails?.dailyTransData?.[0]?.allServices?.reduce((acc, service) => {
        if (!service?.categoryIdentifier || !service?.categoryName || !service?.totalBasePrice || !service?.totalDiscPrice) {
            return acc; // Skip invalid entries
        }

        const { categoryIdentifier, categoryName, totalBasePrice, totalDiscPrice } = service;
        const baseAmount = parseFloat(totalBasePrice) || 0; // Ensure it's a valid number
        const discountedAmount = parseFloat(totalDiscPrice) || 0;

        if (!acc[categoryIdentifier]) {
            acc[categoryIdentifier] = { name: categoryName, baseAmount, discountedAmount };
        } else {
            acc[categoryIdentifier].baseAmount += baseAmount;
            acc[categoryIdentifier].discountedAmount += discountedAmount;
        }
        return acc;
    }, {});

    // Convert to array format for Google Charts
    const chartData2 = [
        ["Category", "Base Amount", "Discounted Amount"],
        ...Object.values(categoryRevenueData).map(({ name, baseAmount, discountedAmount }) => [name, baseAmount, discountedAmount])
    ];




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.summaryWrapper}>
                {workDetails !== null ?
                    <div className={styles.bodyWrapper}>
                        <div className={styles.transactions}>
                            <div className={styles.headerWrapper}>
                                Work Details
                            </div>
                            <div className={styles.eachCardWrapper}>
                                <div className={styles.cardTitle} >
                                    Total Services
                                </div>
                                <div className={styles.cardValue} >
                                    {workDetails?.transactions}
                                </div>
                            </div>


                            <div className={styles.eachCardWrapper}>
                                {/* {JSON.stringify(data)} */}
                                <div style={{ width: "100%", minWidth: "300px", height: "400px" }}>
                                    <Chart
                                        chartType="PieChart"
                                        data={chartData}
                                        options={{
                                            title: "Service Distribution by Category",
                                            pieHole: 0.4, // For Donut Chart effect
                                            is3D: true, // 3D Pie Chart effect
                                            backgroundColor: "transparent",
                                            legend: { position: "bottom" },
                                            chartArea: { width: "80%", height: "80%" },
                                        }}
                                        width="100%"
                                        height="400px"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.dataWrapper}>
                            {workDetails !== null && <ManageEachStaffWorkBookingData workDetails={workDetails} dates={dates} />}
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.transactions}>
                                <div className={styles.eachCardWrapper}>
                                    <div className={styles.cardTitle} >
                                        Total Revenue
                                    </div>
                                    <div className={styles.cardValue} >
                                        ₹{parseFloat(workDetails?.totalAmount).toFixed(0)}
                                    </div>
                                </div>
                                <div className={styles.eachCardWrapper}>
                                    <Chart
                                        width={"100%"}
                                        height={"360px"}
                                        chartType="PieChart"
                                        data={[["Category", "Revenue"], ...chartData2.slice(1).map(([name, , discounted]) => [name, discounted])]}
                                        options={{
                                            title: "Revenue Distribution by Category",
                                            pieHole: 0.4, // Optional: Donut style
                                            legend: { position: "bottom" },
                                        }}
                                    />
                                </div>
                                {/* <div className={styles.eachCardWrapper}>
                                    <Chart
                                        width={"100%"}
                                        height={"360px"}
                                        chartType="BarChart"
                                        data={chartData2}
                                        options={{
                                            title: "Base vs Discounted Revenue per Category",
                                            chartArea: { width: "60%" },
                                            hAxis: { title: "Revenue", minValue: 0 },
                                            vAxis: { title: "Category" },
                                        }}
                                    />
                                </div> */}
                            </div>

                        </div>
                    </div>
                    :
                    <div className={styles.bodyWrapper}>
                        <div className={styles.noWorkLeft}>
                            <div>
                                No work found!
                            </div>
                        </div>
                        <div className={styles.noworkIcon} >
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/LokaciPro/project-assets/try-changing-dates.png" />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ManageEachStaffWork