import React, { useState, useRef, useContext, useEffect } from 'react';
import { CiBookmarkCheck } from 'react-icons/ci';
import { FaClipboardCheck, FaEdit, FaSpinner } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { getAttendanceSummary, getMonthNumber, padNumber } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './MESAEntryPopUp.module.css';
import MESAEPEachEntry from './MESAEPEachEntry';

const MESAEntryPopUp = ({ isOpen, onClose, triggeredDate, currentMonthYear, staffIdentifier }) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [checkIn, setCheckIn] = useState("09:00")
    const [checkOut, setCheckOut] = useState("")
    const [checkInNotes, setCheckInNotes] = useState('')
    const [checkOutNotes, setCheckOutNotes] = useState('')
    const [attendanceRecords, setAttendanceRecords] = useState([])
    const [isAddingEntry, setIsAddingEntry] = useState(false)

    const markEntryHandler = async () => {
        // console.log(checkIn, checkOut, checkInNotes, checkOutNotes);

        if (!checkIn && !checkOut) {
            console.error("At least one of check-in or check-out time is required.");
            alert("At least one of check-in or check-out time is required.");
            return;
        }

        // If both are provided, validate the check-out time
        if (checkIn && checkOut) {
            const checkInTime = new Date(`2000-01-01T${checkIn}:00`);
            const checkOutTime = new Date(`2000-01-01T${checkOut}:00`);

            if (checkOutTime < checkInTime) {
                console.error("Check-out time cannot be earlier than check-in time.");
                alert("Check-out time cannot be earlier than check-in time.");
                return;
            }
        }

        setIsAddingEntry(true)

        const markStaffAttendance = await fetch(BASE_URL_API + "markStaffAttendance",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    staffIdentifier: staffIdentifier,
                    dateToBeMarked: `${currentMonthYear?.year}-${getMonthNumber(currentMonthYear?.month)}-${padNumber(triggeredDate?.date)}`,
                    inTime: checkIn,
                    outTime: checkOut,
                    dayToBeMarked: triggeredDate?.day,
                    checkInNotes: checkInNotes,
                    checkOutNotes: checkOutNotes
                })
            })

        if (!markStaffAttendance.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const markStaffAttendanceRespo = await markStaffAttendance.json()
            if (markStaffAttendanceRespo.status === "success") {
                toast(`Entry marked successfully!`)
                getStaffAttendanceRecords()
                setIsFormOpen(false)
            } else {
                if (markStaffAttendanceRespo.message === 'tokenExpired') {
                    authCtx.logout()
                }
                console.log(markStaffAttendanceRespo)
            }

        }

        setIsAddingEntry(false)

    };

    const getStaffAttendanceRecords = async () => {
        const getStaffAttendanceRecordsResponse = await fetch(BASE_URL_API + "getStaffAttendanceRecords",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    staffIdentifier: staffIdentifier,
                    date: `${currentMonthYear?.year}-${getMonthNumber(currentMonthYear?.month)}-${padNumber(triggeredDate?.date)}`,
                })
            })

        if (!getStaffAttendanceRecordsResponse.ok) {
            console.log("something went wrong : Server Error")
        } else {
            const getStaffAttendanceRecordsRespo = await getStaffAttendanceRecordsResponse.json()
            if (getStaffAttendanceRecordsRespo.status === "success") {
                setAttendanceRecords(getStaffAttendanceRecordsRespo.response)
            } else {
                if (getStaffAttendanceRecordsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAttendanceRecords([])
                    console.log(getStaffAttendanceRecordsRespo)
                }
            }
        }
    }

    useEffect(() => {
        getStaffAttendanceRecords()
    }, [triggeredDate, staffIdentifier])


    window.onclick = event => event.target.id === "mesaepop" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    function isFutureDate(dateString) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        // Set currentDate time to 00:00:00 to only compare dates, not time
        currentDate.setHours(0, 0, 0, 0);

        // Add one day to the current date
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);

        return inputDate >= nextDay;
    }

    if (!isOpen) return null;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="mesaepop" >
                <div className={styles.contentWrapper}>
                    <div className={styles.mainContent}>
                        <div className={styles.headerWrapper}>
                            <div>
                                {triggeredDate?.day}, {triggeredDate?.date} {currentMonthYear?.month} {currentMonthYear?.year}
                            </div>
                            <div className={styles.closeBtnWrapper}>
                                <button onClick={() => onClose()} >Close</button>
                            </div>


                        </div>
                        <div className={styles.addEntryWrapper}>
                            {isFormOpen ? (
                                <div className={styles.addEntryContainer}>
                                    <div className={styles.entryFormWrapper}>
                                        <div className={styles.eachEntryWrapper}>
                                            <div>
                                                <input type="time" autoFocus onChange={(e) => setCheckIn(e.target.value)} value={checkIn} />
                                            </div>

                                            <div>
                                                <textarea placeholder="Enter notes for check-in time..." rows={4}
                                                    onChange={(e) => setCheckInNotes(e.target.value)}
                                                >

                                                </textarea>
                                            </div>
                                        </div>

                                        <div className={styles.eachEntryWrapper}>
                                            <div>
                                                <input type="time" onChange={(e) => setCheckOut(e.target.value)} value={checkOut} />
                                            </div>

                                            <div>
                                                <textarea placeholder="Enter notes for check-out time..." rows={4}
                                                    onChange={(e) => setCheckOutNotes(e.target.value)}
                                                >

                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.entryActionBtnWrapper}>
                                        {isAddingEntry === true ?
                                            <button className={styles.saveBtn} ><FaSpinner className={styles.spinner} /> Save</button>
                                            :
                                            <button className={styles.saveBtn} onClick={() => markEntryHandler()} >Save</button>
                                        }
                                        <button className={styles.cancelBtn} onClick={() => setIsFormOpen(false)}>Cancel</button>
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.addEntryBtnWrapper}>
                                    {isFutureDate(`${currentMonthYear?.year}-${getMonthNumber(currentMonthYear?.month)}-${padNumber(triggeredDate?.date)}`) === false ?
                                        <button onClick={() => setIsFormOpen(true)}>Add Entry</button>
                                        :
                                        <div className={styles.cannotMarkInFuture} >You can't mark the future attendance!</div>
                                    }
                                </div>
                            )}
                        </div>
                        {attendanceRecords?.length > 0 ?
                            <div className={styles.entriesWrapperWithRecords}>
                                {attendanceRecords?.map((itemAttendance, indexAttendance) => (
                                    <MESAEPEachEntry key={indexAttendance} itemAttendance={itemAttendance} getStaffAttendanceRecords={getStaffAttendanceRecords} staffIdentifier={staffIdentifier} />
                                ))}
                            </div>
                            :
                            <div className={styles.entriesWrapper}>
                                <div className={styles.noRecordsFound}>
                                    No Check-in & Check-out entry found!
                                </div>
                            </div>
                        }

                        <div className={styles.footerWrapper}>
                            <div className={styles.borderWrapper}>
                                <div className={styles.checkInWrapper}>
                                    <div className={styles.titleCaption}>First Check-In</div>
                                    <div className={styles.titleValue}>{getAttendanceSummary(attendanceRecords)?.firstCheckIn}</div>
                                </div>
                                <div className={styles.checkOutWrapper}>
                                    <div className={styles.titleCaption}>Last Check-Out</div>
                                    <div className={styles.titleValue}>{getAttendanceSummary(attendanceRecords)?.lastCheckOut}</div>
                                </div>
                            </div>
                            <div className={styles.totalWrapper}>
                                <div className={styles.titleCaption}>Total Hours</div>
                                <div className={styles.titleValue}>{getAttendanceSummary(attendanceRecords)?.totalHours}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MESAEntryPopUp;
