import React, { useMemo, useState } from "react";
import { Chart } from "react-google-charts";

const PerformanceOverTimeInHeatmap = ({ data }) => {
    const [selectedStatuses, setSelectedStatuses] = useState({
        accepted: true,
        sent: true,
        delivered: true,
        read: true,
    });

    // Toggle checkbox values
    const toggleStatus = (status) => {
        setSelectedStatuses((prev) => ({ ...prev, [status]: !prev[status] }));
    };

    // Generate chart data dynamically
    const chartData = useMemo(() => {
        const headers = ["Date", "Messages"];
        const timeCounts = {};

        data.forEach((item) => {
            ["acceptedOn", "sentOn", "deliveredOn", "readOn"].forEach((key) => {
                if (item[key] && item[key] !== "0000-00-00 00:00:00") {
                    const date = item[key].split(" ")[0]; // Extract only the date
                    if (!timeCounts[date]) {
                        timeCounts[date] = [new Date(date), 0]; // Initialize row
                    }
                    const index = key.replace("On", ""); // Get status name (accepted, sent, etc.)
                    if (selectedStatuses[index]) {
                        timeCounts[date][1] += 1; // Add count only if the checkbox is checked
                    }
                }
            });
        });

        const sortedData = Object.values(timeCounts).sort((a, b) => a[0] - b[0]);

        return sortedData.length > 0 ? [headers, ...sortedData] : [headers, ["2025-03-17", 0]];
    }, [data, selectedStatuses]);

    return (
        <div style={{ width: "100%", padding: "20px", background: "#fff", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0,0,0,0.1)" }}>
            <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Performance Over Time (Heatmap)</h3>

            {/* Checkboxes */}
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", gap: "15px" }}>
                {["accepted", "sent", "delivered", "read"].map((status) => (
                    <label key={status} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <input type="checkbox" checked={selectedStatuses[status]} onChange={() => toggleStatus(status)} />
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </label>
                ))}
            </div>

            {/* Heatmap */}
            <Chart
                width={"100%"}
                height={"350px"}
                chartType="Calendar"
                loader={<div>Loading Chart...</div>}
                data={chartData}
                options={{
                    title: "Message Activity Heatmap",
                    calendar: { cellSize: 15 },
                    colorAxis: { colors: ["#FFF3E0", "#FF6F00"] }, // Orange gradient
                }}
            />
        </div>
    );
};

export default PerformanceOverTimeInHeatmap;
