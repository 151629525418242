import React, { useContext, useEffect, useState } from 'react'
import { CiBookmarkCheck } from 'react-icons/ci'
import { FaClipboardCheck, FaEdit, FaSpinner } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './MESAEPEachEntry.module.css'

const MESAEPEachEntry = (props) => {
    const { itemAttendance, staffIdentifier, getStaffAttendanceRecords } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isDeleting, setIsDeleting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [checkIn, setCheckIn] = useState('')
    const [checkOut, setCheckOut] = useState('')
    const [checkInNotes, setCheckInNotes] = useState('')
    const [checkOutNotes, setCheckOutNotes] = useState('')
    const [isAddingEntry, setIsAddingEntry] = useState(false)
    const [formContent, setFormContent] = useState(itemAttendance)

    const updateEntryHandler = async () => {
        setIsAddingEntry(true)
        const editStaffAttendanceRecordsResponse = await fetch(BASE_URL_API + "editStaffAttendanceRecords",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    formContent: formContent
                })
            })

        if (!editStaffAttendanceRecordsResponse.ok) {
            console.log("something went wrong : Server Error")
        } else {
            const editStaffAttendanceRecordsRespo = await editStaffAttendanceRecordsResponse.json()
            if (editStaffAttendanceRecordsRespo.status === "success") {
                toast(`Record updated successfully!`)
                setIsEditing(false)
                getStaffAttendanceRecords()
            } else {
                if (editStaffAttendanceRecordsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(editStaffAttendanceRecordsRespo)
                }
            }
        }
        setIsAddingEntry(false)

    }

    useEffect(() => {
        setFormContent(itemAttendance)
    }, [])



    const deleteStaffAttendanceRecords = async (k) => {
        setIsDeleting(true)
        const deleteStaffAttendanceRecordsResponse = await fetch(BASE_URL_API + "deleteStaffAttendanceRecords",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    staffIdentifier: staffIdentifier,
                    recordIdentifier: k
                })
            })

        if (!deleteStaffAttendanceRecordsResponse.ok) {
            console.log("something went wrong : Server Error")
        } else {
            const deleteStaffAttendanceRecordsRespo = await deleteStaffAttendanceRecordsResponse.json()
            if (deleteStaffAttendanceRecordsRespo.status === "success") {
                toast(`Record deleted successfully!`)
                getStaffAttendanceRecords()
            } else {
                if (deleteStaffAttendanceRecordsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(deleteStaffAttendanceRecordsRespo)
                }
            }
        }
        setIsDeleting(false)
    }


    return (
        <div className={styles.attendanceRecordWrapper}>
            {isEditing === true ?
                <div className={styles.eachAttendanceRecord} >

                    <div className={styles.addEntryContainer}>
                        <div className={styles.entryFormWrapper}>
                            <div className={styles.eachEntryWrapper}>
                                <div>
                                    <input type="time" autoFocus 
                                        onChange={(e) => setFormContent({
                                            ...formContent,
                                            inTime: e.target.value
                                        })}
                                        value={formContent?.inTime} 
                                    />
                                </div>

                                <div>
                                    <textarea placeholder="Enter notes for check-in time..." rows={4}
                                        onChange={(e) => setFormContent({
                                            ...formContent,
                                            inTimeRemarks: e.target.value
                                        })}
                                        value={formContent?.inTimeRemarks}
                                    >

                                    </textarea>
                                </div>
                            </div>

                            <div className={styles.eachEntryWrapper}>
                                <div>
                                    <input type="time"
                                        onChange={(e) => setFormContent({
                                            ...formContent,
                                            outTime: e.target.value
                                        })}
                                        value={formContent.outTime}
                                    />
                                </div>

                                <div>
                                    <textarea placeholder="Enter notes for check-out time..." rows={4}
                                        onChange={(e) => setFormContent({
                                            ...formContent,
                                            outTimeRemarks: e.target.value
                                        })}
                                        value={formContent?.outTimeRemarks}
                                    >

                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div className={styles.entryActionBtnWrapper}>
                            {isAddingEntry === true ?
                                <button className={styles.saveBtn} ><FaSpinner className={styles.spinner} /> Save</button>
                                :
                                <button className={styles.saveBtn} onClick={() => updateEntryHandler()} >Save</button>
                            }
                            <button className={styles.cancelBtn} onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    </div>

                </div>
                :
                <div className={styles.eachAttendanceRecord} >
                    <div className={styles.ewwrDetailsWrapper}>
                        <div className={styles.inTime}>
                            <div className={styles.timeStamp}>
                                {itemAttendance?.inTime} <CiBookmarkCheck />
                            </div>
                            <div className={styles.timeRemarks}>
                                {itemAttendance?.inTimeRemarks}
                            </div>
                        </div>
                        <div className={styles.connectingLine}>
                            ----------------------------
                        </div>
                        <div className={styles.outTime}>
                            <div className={styles.timeStamp}>
                                {itemAttendance?.outTime} <FaClipboardCheck />
                            </div>
                            <div className={styles.timeRemarks}>
                                {itemAttendance?.outTimeRemarks}
                            </div>
                        </div>
                    </div>
                    <div className={styles.ewwrActionWrapper}>
                        <div className={styles.editDelBtn} >
                            <div onClick={() => setIsEditing(true)} >
                                <FaEdit color={`#a5a5a5`} />
                            </div>
                            <div onClick={() => deleteStaffAttendanceRecords(itemAttendance?.recordIdentifier)} className={styles.deleteBtn}>
                                {isDeleting === true ?
                                    <FaSpinner className={styles.spinner} />
                                    :
                                    <MdDelete color={`#a5a5a5`} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MESAEPEachEntry