import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


import styles from './LeftMenu.module.css'

import { MdMenu, MdDashboard, MdLocationPin, MdOutlineAnalytics, MdPermContactCalendar, MdOutlineReviews, MdGroups, MdOutlineCampaign, MdOutlineDeviceHub, MdSettingsSuggest, MdEditNotifications } from "react-icons/md";
import { RiBuildingLine, RiExchangeBoxLine } from "react-icons/ri";
import { FaShoppingBag, FaListAlt, FaCalendarAlt, FaUsers, FaWpforms, FaHistory, FaRegCreditCard, FaCoins, FaFileInvoice } from "react-icons/fa";
import { FiLogOut } from 'react-icons/fi';
import AuthContext from '../../store/auth-context';
import ActiveStoreSelector from './ActiveStoreSelector';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { IoBagAdd } from 'react-icons/io5';
import { TbReport, TbSettingsAutomation, TbSettingsUp } from 'react-icons/tb';
import { HiSpeakerphone } from "react-icons/hi";
import { VscReferences } from "react-icons/vsc";
import NavItem from './NavItem';

import { FaPeopleGroup } from "react-icons/fa6";


const LeftMenu = (props) => {
    const { isOpen, toggler } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [isStoreSelectorOpen, setIsStoreSelectorOpen] = useState(false)
    const storeSelectorHandler = (k) => {
        setIsStoreSelectorOpen(false)
        asCtx.changeStore(k)
    }
    const [activeSection, setActiveSection] = useState('General')

    useEffect(() => {
        if (activeStore === null) {
            setIsStoreSelectorOpen(true)
        }
    }, [activeStore])

    const checkActivePage = (p, q, r, s) => {
        const pathSegments = window.location?.pathname.split("/").filter(Boolean); // Removes empty elements

        if (p && p !== pathSegments[0]) return false;
        if (q && q !== pathSegments[1]) return false;
        if (r && r !== pathSegments[2]) return false;
        if (s && s !== pathSegments[3]) return false;

        return true;
    };


    return (
        <div className={styles.mainWrapper} id="leftMenuId">
            {isStoreSelectorOpen === true && <ActiveStoreSelector isOpen={isStoreSelectorOpen} onClose={() => setIsStoreSelectorOpen(false)} onSuccess={storeSelectorHandler} />}
            <div>
                <div className={styles.hamLogoWrapper}>
                    <div className={styles.menuIcon} onClick={() => toggler()} >
                        <MdMenu size={24} />
                    </div>
                    {isOpen && <div className={styles.logoWrapper}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/LokaciPro/lokaci-pro-logo.png" />
                    </div>}
                </div>
                <div className={styles.allNavOptionsWrapper}>
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/overview`) &&
                        <Link to={`/overview`} className={`${isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall} `}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('overview') && styles.activeNav}`}>
                                <MdDashboard size={isOpen ? 20 : 22} color={checkActivePage('overview') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Overview</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    <Link to={`/invoice/create`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                        <div className={`${styles.eachNavContainer} ${checkActivePage('invoice') && styles.activeNav}`}>
                            <FaFileInvoice size={isOpen ? 20 : 22} color={checkActivePage('invoice') ? '#007bff' : `#898989`} />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Create Bill</div>
                                </>
                            )}
                        </div>
                    </Link>
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/sessions`) &&
                        <Link to={`/sessions`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('sessions') && styles.activeNav}`}>
                                <IoBagAdd size={isOpen ? 20 : 22} color={checkActivePage('sessions') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Bookings / Sessions</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {/* <NavItem isOpen={isOpen} /> */}
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/bookings`) &&
                        <Link to={`/bookings`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('bookings') && styles.activeNav}`}>
                                <FaHistory size={isOpen ? 20 : 22} color={checkActivePage('bookings') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Bookings History</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    <Link to={`/expenses`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                        title="Manage Expenses"
                    >
                        <div className={`${styles.eachNavContainer} ${checkActivePage('expenses') && styles.activeNav}`}>
                            <FaCoins size={isOpen ? 20 : 22} color={checkActivePage('expenses') ? '#007bff' : `#898989`} />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Manage Expenses</div>
                                </>
                            )}
                        </div>
                    </Link>


                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/deals`) &&
                        <Link to={`/deals`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('deals') && styles.activeNav}`}>
                                <FaShoppingBag size={isOpen ? 20 : 22} color={checkActivePage('deals') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Deals</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/clients`) &&
                        <Link to={`/manage/clients`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('manage', 'clients') && styles.activeNav}`}>
                                <MdPermContactCalendar size={isOpen ? 20 : 22} color={checkActivePage('manage', 'clients') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Clients</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {/* {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/clients`) && */}
                    <Link to={`/memberships`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                        <div className={`${styles.eachNavContainer} ${checkActivePage('memberships') && styles.activeNav}`}>
                            <MdPermContactCalendar size={isOpen ? 20 : 22} color={checkActivePage('memberships') ? '#007bff' : `#898989`} />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Memberships</div>
                                </>
                            )}
                        </div>
                    </Link>
                    {/* } */}
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/invoices`) &&
                        <Link to={`/invoices`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('invoices') && styles.activeNav}`}>
                                <MdOutlineAnalytics size={isOpen ? 20 : 22} color={checkActivePage('invoices') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Invoices</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/calendar`) &&
                        <Link to={`/calendar`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('calendar') && styles.activeNav}`}>
                                <FaCalendarAlt size={isOpen ? 20 : 22} color={checkActivePage('calendar') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Calendar</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/price-list`) &&
                        <Link to={`/manage/price-list`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('manage', 'price-list') && styles.activeNav}`}>
                                <FaListAlt size={isOpen ? 20 : 22} color={checkActivePage('manage', 'price-list') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Price List</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage-stores`) &&
                        <Link to={`/manage-stores`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('manage-stores') && styles.activeNav}`}>
                                <RiBuildingLine size={isOpen ? 20 : 22} color={checkActivePage('manage-stores') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Locations / Stores</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/manage/employees`) &&
                        <Link to={`/manage/employees`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('manage', 'employees') && styles.activeNav}`}>
                                <FaUsers size={isOpen ? 20 : 22} color={checkActivePage('manage', 'employees') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Users</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }
                    <Link to={`/feedbacks`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                        <div className={`${styles.eachNavContainer} ${checkActivePage('feedbacks') && styles.activeNav}`}>
                            <MdOutlineReviews size={isOpen ? 20 : 22} color={checkActivePage('feedbacks') ? '#007bff' : `#898989`} />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Feedbacks </div>
                                </>
                            )}
                        </div>
                    </Link>
                    <Link to={`/staff`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                        <div className={`${styles.eachNavContainer} ${checkActivePage('staff') && styles.activeNav}`}>
                            <FaPeopleGroup size={isOpen ? 20 : 22} color={checkActivePage('staff') ? '#007bff' : `#898989`} />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Manage Staff</div>
                                </>
                            )}
                        </div>
                    </Link>

                    {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/user-registration-forms`) &&
                        <Link to={`/user-registration-forms`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}>
                            <div className={`${styles.eachNavContainer} ${checkActivePage('user-registration-forms') && styles.activeNav}`}>
                                <FaWpforms size={isOpen ? 20 : 22} color={checkActivePage('user-registration-forms') ? '#007bff' : `#898989`} />
                                {isOpen && (
                                    <>
                                        <div className={styles.navIcon}></div>
                                        <div className={styles.navTxt}>Registration Forms</div>
                                    </>
                                )}
                            </div>
                        </Link>
                    }

                    <div>
                        <div className={styles.sectionTitle} onClick={() => setActiveSection('promo')}>
                            Promo
                        </div>
                        {/* {activeSection === 'promo' && */}
                            <div>
                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/communications`) &&
                                    <Link to={`/communications`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Communications"
                                    >
                                        <div className={`${styles.eachNavContainer} ${checkActivePage('communications') && styles.activeNav}`}>
                                            <MdOutlineDeviceHub size={isOpen ? 20 : 22} color={checkActivePage('communications') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Hub</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }
                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/communications/audience/all-audiences`) &&
                                    <Link to={`/communications/audience/all-audiences`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Audiences"
                                    >
                                        <div className={styles.eachNavContainer}>
                                            <MdGroups size={isOpen ? 20 : 22} color={checkActivePage('communications') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Audiences</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }

                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/communications/all-campaigns`) &&
                                    <Link to={`/communications/all-campaigns`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Campaign"
                                    >
                                        <div className={styles.eachNavContainer}>
                                            <MdOutlineCampaign size={isOpen ? 20 : 22} color={checkActivePage('communications') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Campaigns</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }
                            </div>
                        {/* } */}
                    </div>



                    <div>
                        <div className={styles.sectionTitle} onClick={() => setActiveSection('reports')} >
                            Reports
                        </div>
                        {/* {activeSection === 'reports' && */}
                            <div>
                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/user-registration-forms`) &&
                                    <Link to={`/collection-reports`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Collection Report"
                                    >
                                        <div className={styles.eachNavContainer}>
                                            <VscReferences size={isOpen ? 20 : 22} color={checkActivePage('collection-reports') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Collection Reports</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }
                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/user-registration-forms`) &&
                                    <Link to={`/expenses-report`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Expenses Report"
                                    >
                                        <div className={styles.eachNavContainer}>
                                            <TbReport size={isOpen ? 20 : 22} color={checkActivePage('expenses-report') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Expenses Report</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }
                                {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/user-registration-forms`) &&
                                    <Link to={`/outstanding-report`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                        title="Outstanding Payment Report"
                                    >
                                        <div className={styles.eachNavContainer}>
                                            <FaRegCreditCard size={isOpen ? 20 : 22} color={checkActivePage('outstanding-report') ? '#007bff' : `#898989`} />
                                            {isOpen && (
                                                <>
                                                    <div className={styles.navIcon}></div>
                                                    <div className={styles.navTxt}>Outstanding Report</div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                }
                            </div>
                        {/* } */}
                    </div>

                    <div>
                        <div className={styles.sectionTitle} onClick={() => setActiveSection('settings')} >
                            Settings
                        </div>
                        {/* {activeSection === 'settings' && */}
                            <div>
                                {/* {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/settings/account`) && */}
                                <Link to={`/settings/set-up`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                    title="Account Set-up"
                                >
                                    <div className={`${styles.eachNavContainer} ${checkActivePage('settings', 'set-up') && styles.activeNav}`}>
                                        <TbSettingsUp size={isOpen ? 20 : 22} color={checkActivePage('settings', 'set-up') ? '#007bff' : `#898989`} />
                                        {isOpen && (
                                            <>
                                                <div className={styles.navIcon}></div>
                                                <div className={styles.navTxt}>Account Set-up</div>
                                            </>
                                        )}
                                    </div>
                                </Link>
                                {/* } */}
                                {/* {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/settings/account`) && */}
                                <Link to={`/settings/account`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                    title="Account Setting"
                                >
                                    <div className={`${styles.eachNavContainer} ${checkActivePage('settings', 'account') && styles.activeNav}`}>
                                        <MdSettingsSuggest size={isOpen ? 20 : 22} color={checkActivePage('settings', 'account') ? '#007bff' : `#898989`} />
                                        {isOpen && (
                                            <>
                                                <div className={styles.navIcon}></div>
                                                <div className={styles.navTxt}>Account Setting</div>
                                            </>
                                        )}
                                    </div>
                                </Link>
                                {/* } */}
                                {/* {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/settings/account`) && */}
                                <Link to={`/settings/automation`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                    title="Automation Setting"
                                >
                                    <div className={`${styles.eachNavContainer} ${checkActivePage('settings', 'automation') && styles.activeNav}`}>
                                        <TbSettingsAutomation size={isOpen ? 20 : 22} color={checkActivePage('settings', 'automation') ? '#007bff' : `#898989`} />
                                        {isOpen && (
                                            <>
                                                <div className={styles.navIcon}></div>
                                                <div className={styles.navTxt}>Automation Setting</div>
                                            </>
                                        )}
                                    </div>
                                </Link>
                                {/* } */}
                                {/* {JSON.parse(authCtx?.loginData)?.navigatorData?.includes(`/settings/account`) && */}
                                <Link to={`/settings/notifications`} className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                                    title="Notifications Setting"
                                >
                                    <div className={`${styles.eachNavContainer} ${checkActivePage('settings', 'notifications') && styles.activeNav}`}>
                                        <MdEditNotifications size={isOpen ? 20 : 22} color={checkActivePage('settings', 'notifications') ? '#007bff' : `#898989`} />
                                        {isOpen && (
                                            <>
                                                <div className={styles.navIcon}></div>
                                                <div className={styles.navTxt}>Notifications Setting</div>
                                            </>
                                        )}
                                    </div>
                                </Link>
                                {/* } */}
                            </div>
                        {/* } */}
                    </div>

                </div>
            </div>
            <div className={styles.hfWrapper}>
                {/* {JSON.parse(authCtx?.loginData)?.storesData?.length > 1 && */}
                <div className={styles.changeActiveStore}
                    onClick={() => setIsStoreSelectorOpen(true)}
                >
                    <div className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                    >
                        <div className={styles.eachNavContainer}>
                            <RiExchangeBoxLine size={isOpen ? 20 : 22} color="#00000090" />
                            {isOpen && (
                                <>
                                    <div className={styles.navIcon}></div>
                                    <div className={styles.navTxt}>Change Store</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* } */}

                <div className={isOpen ? styles.eachNavWrapper : styles.eachNavWrapperSmall}
                    onClick={() => authCtx.logout()}
                >
                    <div className={styles.eachNavContainer}>
                        <FiLogOut size={isOpen ? 20 : 22} color="#898989" />
                        {isOpen && (
                            <>
                                <div className={styles.navIcon}></div>
                                <div className={styles.navTxt}>Logout</div>
                            </>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LeftMenu