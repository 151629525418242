import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./AccountSetup.module.css";
import { FaCheckCircle, FaList, FaPhone, FaUser, FaUsers, FaArrowRight, FaCheckDouble } from "react-icons/fa";
import { Link } from "react-router";

const steps = [
    { id: 1, text: "Price List", icon: <FaCheckCircle size={18} />, setUpUrl : '/manage/price-list' },
    { id: 2, text: "Update Store Phone Number", icon: <FaPhone size={18} />, setUpUrl : `/settings/store/primary-details` },
    { id: 3, text: "Update Staff Details", icon: <FaUser size={18} />, setUpUrl : `/manage/employees` },
    { id: 4, text: "Update Invoice Details", icon: <FaList size={18} /> },
    { id: 5, text: "Update Clients", icon: <FaUsers size={18} />, setUpUrl : `/manage/clients` },
];

const AccountSetup = () => {
    const [completedSteps, setCompletedSteps] = useState(0);
    const [completedSubSteps, setCompletedSubSteps] = useState({});

    const handleStepClick = (id) => {
        if (id > completedSteps) setCompletedSteps(id);
    };


    return (
        <div className={styles.mainWrapper} >
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.titleWrapper}>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            className={styles.title}
                        >
                            Account Setup
                        </motion.div>
                    </div>
                    <div className={styles.progressBarWrapper}>
                        <div className={styles.progressBarContainer}>
                            <motion.div
                                className={styles.progressBar}
                                initial={{ width: "0%" }}
                                animate={{ width: `${(completedSteps / steps.length) * 100}%` }}
                                transition={{ duration: 0.5 }}
                            />
                        </div>
                        <div className={styles.percentageCount}>
                            {parseInt((completedSteps / steps?.length) * 100)}% completed
                        </div>
                    </div>
                </div>
                <div className={styles.bodyWrapper}>
                    <div className={styles.container}>

                        <div className={styles.stepsContainer}>
                            {steps.map((step, index) => (
                                <motion.div
                                    key={step.id}
                                    className={`${styles.step} ${index < completedSteps ? styles.completed : ""}`}
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                    onClick={() => handleStepClick(index + 1)}
                                >
                                    <div className={styles.stepHeader}>
                                        <span className={styles.icon}>{step.icon}</span>
                                        <span className={styles.stepText}>Step {step.id}: {step.text}</span>
                                    </div>
                                    {index >= completedSteps ?
                                        <div className={styles.btnWrapper}>
                                            <Link to={step?.setUpUrl}>Complete Set-up</Link>
                                        </div>
                                        :
                                        <div className={styles.btnWrapperCompleted}>
                                            <Link to={`/price-list`}><FaCheckDouble />Completed</Link>
                                        </div>
                                    }
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default AccountSetup;