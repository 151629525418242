import React, { useContext, useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router';
import InvoiceSelector from '../../../Components/Invoices/PreviewInvoice/InvoiceSelector';
import { invoiceThemes } from '../../../Helper/invoiceThemes';
import styles from './InvoicePreviewer.module.css';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import tinycolor from "tinycolor2"; // For advanced color manipulation
import { IoIosMail } from 'react-icons/io';
import { MdChevronLeft, MdMessage, MdOutlineFileDownload } from 'react-icons/md';
import { FaPlus, FaWhatsapp } from 'react-icons/fa';
import { BASE_URL_API } from '../../../References/URLs';
import AuthContext from '../../../store/auth-context';
import ActiveStoreContext from '../../../store/ActiveStoreContext';
import { toast } from 'react-toastify';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';


const InvoicePreviewer = () => {
    const { sessionIdentifier } = useParams();
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const navigate = useNavigate()
    // console.log(activeStore)
    const [isMarkingCompleted, setIsMarkingCompleted] = useState(false)
    const [isSendingInvoice, setIsSendingInvoice] = useState(false)
    const [invoiceDataMaster, setInvoiceDataMaster] = useState({
        "sessionIdentifier": "201719293519003381173645490220250110",
        "invoiceDate": "2025-01-11",
        "dueDate": "2025-01-11",
        "invoiceNumber": "5345234",
        "clientData": {
            "clientStoreRelationIdentifier": "201719293519003381WALKIN173657378120250111",
            "clientName": "",
            "phoneNumber": "",
            "gender": "Male",
            "dateOfBirth": "",
            "ID": "2474",
            "storeIdentifier": "201719293519003381",
            "vender_email": "",
            "countryCode": "",
            "mobile": "9999648406",
            "alternateNumber": "",
            "whatsAppNumber": "",
            "salutation": "Mr.",
            "client_name": "Navneet Chandan",
            "client_email": "navneet@lokaci.com",
            "client_address": "Noida",
            "city": "",
            "state": "",
            "pincode": "",
            "client_birthday": "1999-07-25",
            "clientDoa": "",
            "added_on": "2025-01-11 11:06:21",
            "isDeleted": "0",
            "deletedOn": "0000-00-00 00:00:00",
            "deletionRemark": "",
            "registrationSource": "",
            "relatives": "[{\"relativeIdentifier\": null, \"salutation\": null, \"firstName\": null, \"middleName\": null, \"lastName\": null, \"dateOfBirth\": null, \"gender\": null, \"relationToFamilyHead\": null}]"
        },
        "productData": [
            {
                "productIdentifier": "8677d8ae-9227-478d-ae9a-2086cf118aa1",
                "identifier": "201719293519003381173644897610B5C15C0FA179FA",
                "qty": 1,
                "price": "50",
                "total": 59,
                "consultedBy": [
                    {
                        "id": "1736501472C609EFB6D1755CB1",
                        "staffName": "Shashank Sir",
                        "contribution": 100
                    }
                ],
                "servedBy": [
                    {
                        "id": "17365039830E5FE85660260C3C",
                        "staffName": "Owner Madam",
                        "contribution": 100
                    }
                ],
                "productTitle": "EYEBROW THREAD",
                "gst": "18",
                "gstValue": 9,
                "discount": 0
            },
            {
                "productIdentifier": "bf1faebf-91fe-4fe8-ab79-952b76fb8c8a",
                "identifier": "2017192935190033811736449990BD229EAFF45199A6",
                "qty": 2,
                "price": "500",
                "total": 950,
                "consultedBy": [
                    {
                        "id": "1736501472C609EFB6D1755CB1",
                        "staffName": "Shashank Sir",
                        "contribution": 100
                    }
                ],
                "servedBy": [
                    {
                        "id": "17365039830E5FE85660260C3C",
                        "staffName": "Owner Madam",
                        "contribution": 100
                    }
                ],
                "productTitle": "HALF BACK NORMAL",
                "gstValue": 0,
                "discount": 5
            }
        ],
        "discountType": "percentage",
        "tncData": [
            {
                "title": "Non refundable",
                "detail": "Can't be refunded"
            }
        ],
        "customFields": [
            {
                "fieldTitle": "Coupon",
                "calculationLogic": "subtract",
                "calculationType": "value",
                "value": 204.5
            }
        ],
        "shipmentPlaceOfSupply": "Uttar Pradesh",
        "totalAmount": 1009,
        "invoiceType": "ICICI Bank",
        "isRoundingOff": false,
        "roundOffValue": 0,
        "paymentMode": "Cash"
    })
    // Define extensive themes with metadata and customization options.. fetched 
    const themesWrapper = invoiceThemes
    const [selectedTheme, setSelectedTheme] = useState(
        {
            id: "TallyTheme",
            title: "Tally Theme",
            details: "A simple, professional theme with clean lines and clear typography.",
            meta: {
                primaryColor: "#4CAF50",
                secondaryColor: "#F5F5F5",
                textColor: "#000000",
                primaryTextColor: "#1A1A1A",
                secondaryTextColor: "#666666",
                backgroundColor: "#FFFFFF",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif"
            }
        }
    );
    const [selectedInvoiceFormat, setSelectedInvoiceFormat] = useState('Simple Invoice');
    const [pdfSize, setPdfSize] = useState('A4');
    const [palette, setPalette] = useState([
        { backgroundColor: "#FFFFFF", textColor: "#000000" },
        { backgroundColor: "#000000", textColor: "#FFFFFF" },
    ]);
    const [selectedPallete, setSelectedPallete] = useState({
        backgroundColor: '#ffffff',
        textColor: '#000000'
    })
    const [customUserColor, setCustomUserColor] = useState(null)

    const invoiceFormats = [
        { label: 'Simple Invoice', layout: 'A clean, straightforward invoice format.' },
        { label: 'Itemized Invoice', layout: 'Detailed breakdown of items and services.' },
        { label: 'Tax Invoice', layout: 'Includes tax details and calculations.' },
    ];

    const pdfSizes = {
        A4: { width: 210, height: 297 },
        A5: { width: 148, height: 210 },
        Letter: { width: 216, height: 279 },
        Legal: { width: 216, height: 356 },
    };

    const handleThemeSelect = (theme) => {
        setSelectedTheme(theme)
    }

    const handleFormatSelect = (format) => setSelectedInvoiceFormat(format);
    const handlePdfSizeChange = (size) => setPdfSize(size);

    // const generatePDF = async () => {
    //     const invoiceElement = document.getElementById("invoicePreview"); // Target the invoice container
    //     const size = pdfSizes[pdfSize];

    //     if (!invoiceElement) {
    //         console.error("Invoice element not found!");
    //         return;
    //     }

    //     const canvas = await html2canvas(invoiceElement, { scale: 2 }); // Capture the container as an image
    //     const imageData = canvas.toDataURL("image/png"); // Convert canvas to image
    //     const pdf = new jsPDF("portrait", "mm", [size.width, size.height]);

    //     pdf.addImage(imageData, "PNG", 0, 0, size.width, size.height);
    //     pdf.save("Invoice.pdf"); // Download the PDF
    // };

    // const generatePDF = async () => {
    //     const invoiceElement = document.getElementById("invoicePreview"); // Target the invoice container
    //     const size = pdfSizes[pdfSize]; // Get selected PDF size dimensions

    //     if (!invoiceElement) {
    //         console.error("Invoice element not found!");
    //         return;
    //     }

    //     // Capture the container as an image using html2canvas
    //     const canvas = await html2canvas(invoiceElement, { scale: 2 }); // Higher scale for better quality
    //     const imageData = canvas.toDataURL("image/png"); // Convert canvas to image

    //     const contentWidth = canvas.width; // Width of the captured content
    //     const contentHeight = canvas.height; // Height of the captured content

    //     // Calculate aspect ratio
    //     const aspectRatio = contentWidth / contentHeight;

    //     // Calculate width and height for the PDF while maintaining the aspect ratio
    //     let pdfWidth = size.width;
    //     let pdfHeight = pdfWidth / aspectRatio;

    //     // If calculated height exceeds PDF size height, adjust width and height
    //     if (pdfHeight > size.height) {
    //         pdfHeight = size.height;
    //         pdfWidth = pdfHeight * aspectRatio;
    //     }

    //     // Create jsPDF instance with specified size
    //     const pdf = new jsPDF("portrait", "mm", [size.width, size.height]);

    //     // Center the image in the PDF
    //     const xOffset = (size.width - pdfWidth) / 2;
    //     // const yOffset = (size.height - pdfHeight) / 2;
    //     const yOffset = 0;

    //     pdf.addImage(imageData, "PNG", xOffset, yOffset, pdfWidth, pdfHeight);
    //     pdf.save("Invoice.pdf"); // Download the PDF
    // };

    const generatePDF = async () => {
        const invoiceElement = document.getElementById("invoicePreview"); // Target the invoice container
        const size = pdfSizes[pdfSize]; // Get selected PDF size dimensions

        if (!invoiceElement) {
            console.error("Invoice element not found!");
            return;
        }

        // Capture the container as an image using html2canvas
        const canvas = await html2canvas(invoiceElement, {
            scale: 2, // Higher scale for better quality
            useCORS: true, // Ensure cross-origin resources (like images) are loaded
            onclone: (clonedDocument) => {
                const logoImage = clonedDocument.getElementById("logo");
                if (logoImage) {
                    logoImage.crossOrigin = "anonymous"; // Handle CORS issues for hosted logos
                }
            },
        });

        const imageData = canvas.toDataURL("image/png"); // Convert canvas to image

        const contentWidth = canvas.width; // Width of the captured content
        const contentHeight = canvas.height; // Height of the captured content

        // Calculate aspect ratio
        const aspectRatio = contentWidth / contentHeight;

        // Calculate width and height for the PDF while maintaining the aspect ratio
        let pdfWidth = size.width;
        let pdfHeight = pdfWidth / aspectRatio;

        // If calculated height exceeds PDF size height, adjust width and height
        if (pdfHeight > size.height) {
            pdfHeight = size.height;
            pdfWidth = pdfHeight * aspectRatio;
        }

        // Create jsPDF instance with specified size
        const pdf = new jsPDF("portrait", "mm", [size.width, size.height]);

        // Center the image in the PDF
        const xOffset = (size.width - pdfWidth) / 2;
        const yOffset = 0; // Start from the top

        pdf.addImage(imageData, "PNG", xOffset, yOffset, pdfWidth, pdfHeight);
        pdf.save("Invoice.pdf"); // Download the PDF
    };




    const [expandedGroups, setExpandedGroups] = useState(
        themesWrapper.map(() => false) // Default: All groups collapsed
    );

    const toggleGroup = (index) => {
        setExpandedGroups((prevState) =>
            prevState.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
        );
    };

    // Function to calculate contrast text color
    const generateContrastColor = (bgColor) => {
        return tinycolor(bgColor).isLight() ? "#000000" : "#FFFFFF";
    };
    // Generate the palette for a single base color
    const generatePaletteFromBase = (baseColor) => {
        const textColor = generateContrastColor(baseColor);

        const tableHeaderColor = tinycolor(baseColor).darken(10).toHexString();
        const tableHeaderTextColor = generateContrastColor(tableHeaderColor);

        const tableBodyBackgroundColor = tinycolor(baseColor).lighten(5).toHexString();
        const tableBodyTextColor = generateContrastColor(tableBodyBackgroundColor);
        const tableBodyBorderColor = tinycolor(baseColor).darken(15).toHexString();

        const primaryButtonColor = tinycolor(baseColor).complement().lighten(10).toHexString();
        const primaryButtonTextColor = generateContrastColor(primaryButtonColor);

        const secondaryButtonColor = tinycolor(baseColor).spin(30).darken(10).toHexString();
        const secondaryButtonTextColor = generateContrastColor(secondaryButtonColor);

        const tertiaryButtonColor = tinycolor(baseColor).spin(-30).lighten(15).toHexString();
        const tertiaryButtonTextColor = generateContrastColor(tertiaryButtonColor);

        const invoiceNotesColor = tinycolor(baseColor).desaturate(20).lighten(25).toHexString();
        const sectionDividingLineColor = tinycolor(baseColor).darken(20).setAlpha(0.5).toRgbString();

        const invoiceHeaderBackgroundColor = tinycolor(baseColor).darken(5).toHexString();
        const invoiceHeaderTextColor = generateContrastColor(invoiceHeaderBackgroundColor);

        const footerBackgroundColor = tinycolor(baseColor).darken(8).toHexString();
        const footerTextColor = generateContrastColor(footerBackgroundColor);

        const warningTextColor = tinycolor(baseColor).spin(120).saturate(40).toHexString();
        const successTextColor = tinycolor(baseColor).spin(-120).saturate(50).toHexString();

        const watermarkColor = tinycolor(baseColor).lighten(40).setAlpha(0.1).toRgbString();

        const accentLineColor = tinycolor(baseColor).lighten(15).toHexString();

        return {
            backgroundColor: baseColor,
            textColor,
            tableHeaderColor,
            tableHeaderTextColor,
            tableBodyBackgroundColor,
            tableBodyTextColor,
            tableBodyBorderColor,
            primaryButtonColor,
            primaryButtonTextColor,
            secondaryButtonColor,
            secondaryButtonTextColor,
            tertiaryButtonColor,
            tertiaryButtonTextColor,
            invoiceNotesColor,
            sectionDividingLineColor,
            invoiceHeaderBackgroundColor,
            invoiceHeaderTextColor,
            footerBackgroundColor,
            footerTextColor,
            warningTextColor,
            successTextColor,
            watermarkColor,
            accentLineColor,
        };
    };

    // Generate unique base colors
    const generateBaseColors = (count, existingColors = new Set()) => {
        const randomColor = () =>
            `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;
        const baseColors = [];

        while (baseColors.length < count) {
            const newColor = randomColor();
            if (!existingColors.has(newColor)) {
                baseColors.push(newColor);
                existingColors.add(newColor);
            }
        }

        return baseColors;
    };

    // const handleGenerateMore = () => {
    //     const baseColors = generateBaseColors(5, new Set(palette.map((p) => p.backgroundColor)));
    //     const newPalettes = baseColors.map((baseColor) => generatePaletteFromBase(baseColor));
    //     setPalette((prev) => [...prev, ...newPalettes]);
    // };

    const handleGenerateMore = (userBaseColor = null) => {
        // Validate the user-provided base color (if any)
        const isValidHex = (color) =>
            /^#([0-9A-F]{3}){1,2}$/i.test(color);

        const baseColors = userBaseColor && isValidHex(userBaseColor)
            ? [userBaseColor] // Use the user-provided base color
            : generateBaseColors(5, new Set(palette.map((p) => p.backgroundColor))); // Generate colors automatically if no valid input

        // Generate palettes from the base colors
        const newPalettes = baseColors.map((baseColor) =>
            generatePaletteFromBase(baseColor)
        );

        // Update the palette state
        setPalette((prev) => [...prev, ...newPalettes]);
    };


    useEffect(() => {
        handleGenerateMore(); // Generate initial palette
    }, []);


    const getInvoiceData = async () => {
        console.log('getting invoice data...')
        const getInvoiceDataResponse = await fetch(BASE_URL_API + "getInvoiceData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sessionIdentifier: sessionIdentifier
                })
            });

        if (!getInvoiceDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInvoiceDataRespo = await getInvoiceDataResponse.json()
            if (getInvoiceDataRespo.status === "success") {
                setInvoiceDataMaster(getInvoiceDataRespo?.response)
            } else {
                if (getInvoiceDataRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            // console.log("fetched data end")
            console.log(getInvoiceDataRespo)
            // console.log("fetched data end")
        }
    }

    const sendInvoiceHandler = async () => {
        setIsSendingInvoice(true)
        const sendInvoiceResponse = await fetch(BASE_URL_API + "sendInvoiceNew",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    sessionIdentifier: sessionIdentifier,
                    storeName: activeStore?.storeName,
                    customerCare: activeStore?.storePrimaryPhone,
                })
            });

        if (!sendInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendInvoiceRespo = await sendInvoiceResponse.json();
            if (sendInvoiceRespo.status === 'success') {
                // setInvoiceKey(sendInvoiceRespo?.response)
                toast(`Invoice shared successfully!`)
            } else {
                if (sendInvoiceRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    // setHasInvoiceShared('NOT_SHARING')
                }
            }
            console.log(sendInvoiceRespo)
        }
        setIsSendingInvoice(false)
    }
    // const sendInvoiceHandler = async () => {
    //     setIsSendingInvoice(true)
    //     const sendInvoiceResponse = await fetch(BASE_URL_API + "sendInvoice",
    //         {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 token: authCtx.token,
    //                 bookingIdentifier: sessionIdentifier
    //             })
    //         });

    //     if (!sendInvoiceResponse.ok) {
    //         console.log("Something went wrong : Server Error")
    //     } else {
    //         const sendInvoiceRespo = await sendInvoiceResponse.json();
    //         if (sendInvoiceRespo.status === 'success') {
    //             // setInvoiceKey(sendInvoiceRespo?.response)
    //             toast(`Invoice shared successfully!`)
    //         } else {
    //             if (sendInvoiceRespo.message === 'tokenExpired') {
    //                 authCtx.logout()
    //             } else {
    //                 // setHasInvoiceShared('NOT_SHARING')
    //             }
    //         }
    //         console.log(sendInvoiceRespo)
    //     }
    //     setIsSendingInvoice(false)
    // }



    useEffect(() => {
        getInvoiceData()
    }, [sessionIdentifier])

    console.log(activeStore.storeIdentifier)

    const markAsCompletedHandler = async () => {
        // setIsUpdatingStatus(true)
        setIsMarkingCompleted(true)
        const updateSessionStatusResponse = await fetch(BASE_URL_API + "updateSessionStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: sessionIdentifier,
                    statusToBeUpdated: 'BOOKING_COMPLETED',
                    storeName: activeStore?.storeName,
                    storePrimaryPhone: activeStore?.storePrimaryPhone
                })
            });

        if (!updateSessionStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateSessionStatusRespo = await updateSessionStatusResponse.json();
            if (updateSessionStatusRespo.status == "success") {
                toast(`Invoice marked successfully!`)
                navigate('/invoice/create')
                // setMarkCompleted(true)
            } else {
                if (updateSessionStatusRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateSessionStatusRespo);
        }
        setIsMarkingCompleted(false)
        // setIsUpdatingStatus(false)
    }

    if (activeStore === null) {
        return (
            <div>
                Please select a store!
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <Link to={`/invoice/create/${sessionIdentifier}`} className={styles.backBtnWrapper}>
                    <MdChevronLeft />
                    <div>Edit Invoice</div>
                </Link>
                <div className={styles.pageTitle}>Invoice Preview</div>
                <div className={styles.markAsCompleted} >
                    {isMarkingCompleted === false ?
                        <button className={styles.markNow} onClick={() => markAsCompletedHandler()} >Mark as Completed</button>
                        :
                        <button className={styles.pleaseWait}>
                            <div className={styles.spinner}>
                                <AiOutlineLoading3Quarters size={16} />
                            </div>
                                Please wait...
                        </button>
                    }
                </div>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.leftWrapper}>
                    <div className={styles.leftContainer}>
                        <div className={styles.titleWrapper} >Select Theme</div>
                        <div className={styles.themesWrapper}>
                            {themesWrapper.map((group, groupIndex) => (
                                <div key={groupIndex} className={styles.eachThemeGroup}>
                                    <div
                                        className={styles.themeGroupTitle}
                                        onClick={() => toggleGroup(groupIndex)}
                                        aria-expanded={expandedGroups[groupIndex]}
                                    >
                                        <div>{group.themeGroupTitle}</div>
                                        <div>
                                            {expandedGroups[groupIndex] ? (
                                                <BiChevronUp size={20} />
                                            ) : (
                                                <BiChevronDown size={20} />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            expandedGroups[groupIndex]
                                                ? styles.themeDataExpanded
                                                : styles.themeDataCollapsed
                                        }
                                        style={{
                                            maxHeight: expandedGroups[groupIndex] ? '500px' : '0px',
                                            overflow: 'hidden',
                                            transition: 'max-height 0.3s ease-out',
                                        }}
                                    >
                                        {group.themeData.map((theme, index) => (
                                            <div
                                                key={index}
                                                className={selectedTheme === theme ? styles.themeOptionActive : styles.themeOption}
                                                onClick={() => handleThemeSelect(theme)}
                                            >
                                                <div className={styles.themeTitle} >{theme.title}</div>
                                                {/* <div>{theme.details}</div> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div>
                            <div className={styles.choosePalleteTitle}>
                                Choose Color Pallete
                            </div>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fill, minmax(45px, 1fr))",
                                    gap: "10px",
                                    justifyContent: 'center',
                                    padding: '6px 12px'
                                }}
                                className={styles.colorPalleteWrapper}
                            >
                                {palette.map((color, index) => (
                                    <div
                                        onClick={() => setSelectedPallete(color)}
                                        key={index}
                                        style={{
                                            backgroundColor: color.backgroundColor,
                                            color: color.textColor,
                                            padding: "2px",
                                            width: "43px",
                                            height: "43px",
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                        className={styles.eachColorPallete}
                                    >
                                    </div>
                                ))}

                                <div
                                    onClick={() => handleGenerateMore()}
                                    style={{
                                        padding: "2px",
                                        width: "43px",
                                        height: "43px",
                                        borderRadius: "8px",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    }}
                                    className={`${styles.eachColorPallete} ${styles.addMore}`}
                                >
                                    <FaPlus />
                                </div>
                                {/* <div
                                    style={{
                                        padding: "2px",
                                        width: "230px",
                                        height: "43px",
                                        borderRadius: "8px",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <div>
                                        <input value={customUserColor} onChange={(e) => setCustomUserColor(e.target.value)} />
                                    </div>
                                    <div>
                                        <button onClick={() => handleGenerateMore(customUserColor)} >Update </button>
                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>
                </div>

                {/* Center Wrapper: Invoice Preview */}
                <div className={styles.centerWrapper}>
                    <div
                        id="invoicePreview"
                        // Add this ID to target for PDF generation
                        className={styles.invoicePreview}
                        style={{ backgroundColor: selectedTheme?.meta?.backgroundColor, height: '297mm' }}
                    >
                        {selectedTheme && (
                            <InvoiceSelector selectedPallete={selectedPallete} invoiceDataMaster={invoiceDataMaster} selectedInvoice={selectedTheme} />
                        )}
                        {!selectedTheme && <div>Please select a theme to preview the invoice.</div>}
                    </div>
                </div>

                {/* Right Wrapper: Options and Export */}
                <div className={styles.rightWrapper}>
                    <div>
                        <h3>Options</h3>
                        <div>
                            <div className={styles.sizeSelector}>
                                <label htmlFor="pdfSize">Select PDF Size:</label>
                                <select
                                    id="pdfSize"
                                    value={pdfSize}
                                    onChange={(e) => handlePdfSizeChange(e.target.value)}
                                >
                                    {Object.keys(pdfSizes).map((sizeKey) => (
                                        <option key={sizeKey} value={sizeKey}>
                                            {sizeKey}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <div className={styles.shareInvoiceWrapper}>
                                <div className={styles.shareInvoiceTitle} >Share Invoice</div>
                                <div className={styles.menuWrapper} >
                                    <div className={styles.eachMenu}>
                                        <div>
                                            <IoIosMail size={28} />
                                        </div>
                                        <div className={styles.menuTxt} >
                                            Mail
                                        </div>
                                    </div>

                                    <div className={styles.eachMenu}>
                                        <div>
                                            <MdMessage size={28} />
                                        </div>
                                        <div className={styles.menuTxt} >
                                            Message
                                        </div>
                                    </div>

                                    {isSendingInvoice ? (
                                        <div className={`${styles.eachMenu} ${styles.sendingInvoice}`}>
                                            <div className={styles.spinner}>
                                                <AiOutlineLoading3Quarters size={28} />
                                            </div>
                                            <div className={styles.menuTxt}>
                                                Sending...
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.eachMenu} onClick={() => sendInvoiceHandler()}>
                                            <div>
                                                <FaWhatsapp size={28} />
                                            </div>
                                            <div className={styles.menuTxt}>
                                                WhatsApp
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className={styles.shareInvoiceWrapper}>
                                <div className={styles.shareInvoiceTitle} >Download Invoice</div>
                                <div className={styles.menuWrapper} >
                                    <div className={styles.eachMenu} onClick={generatePDF} >
                                        <div>
                                            <MdOutlineFileDownload size={28} />
                                        </div>
                                        <div className={styles.menuTxt} >
                                            Download
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default InvoicePreviewer;
