import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaCalendar, FaList } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { CiGrid41 } from 'react-icons/ci';
import styles from './ManageEachStaffAttendance.module.css';
import MESATabular from './MESATabular';
import MESAList from './MESAList';
import MESACalendar from './MESACalendar';
import MESAEntryPopUp from './MESAEntryPopUp';
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { getMonthNumber, padNumber } from '../../Helper/calculations';
import { BsThreeDotsVertical } from 'react-icons/bs';
import MESAShiftManager from './MESAShiftManager';

const ManageEachStaffAttendance = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const { staffIdentifier } = useParams()
    const [activeView, setActiveView] = useState('List');
    const [currentMonthYear, setCurrentMonthYear] = useState({
        month: new Date().toLocaleString('en-US', { month: 'long' }), // Full month name
        year: new Date().getFullYear()
    })
    const [days, setDays] = useState([])
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef(null);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [startYear, setStartYear] = useState(new Date().getFullYear() - 5);
    const [isMarkingAttendance, setIsMarkingAttendance] = useState(false)
    const [triggeredDate, setTriggeredDate] = useState('')
    const [attendanceRecords, setAttendanceRecords] = useState([])
    const [isShiftManagerOpen, setIsShiftManagerOpen] = useState(false)

    const [generalShift, setGeneralShift] = useState(null)
    const [weekOff, setWeekOff] = useState('')

    const toggleDatePicker = () => {
        setShowDatePicker(prev => !prev);
    };

    const selectMonthYear = (month, year) => {
        setCurrentMonthYear({ month, year });
        setShowDatePicker(false);
    };

    const handleClickOutside = (event) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target)
        ) {
            setShowDatePicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Function to handle left and right arrow navigation
    const handleMonthChange = (direction) => {
        let currentIndex = months.indexOf(currentMonthYear.month);
        let newYear = currentMonthYear.year;
        let newIndex = currentIndex + direction;

        if (newIndex < 0) {
            newYear -= 1;
            newIndex = 11; // December
        } else if (newIndex > 11) {
            newYear += 1;
            newIndex = 0; // January
        }

        setCurrentMonthYear({ month: months[newIndex], year: newYear });
    };


    const getDaysInMonth = (month, year) => {
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); // Convert month name to index
        const totalDays = new Date(year, monthIndex + 1, 0).getDate(); // Get total days in month

        return Array.from({ length: totalDays }, (_, i) => {
            const date = i + 1;
            const dayName = new Date(year, monthIndex, date).toLocaleDateString('en-US', { weekday: 'long' });

            return { date, day: dayName };
        });
    };


    useEffect(() => {
        setDays(getDaysInMonth(currentMonthYear.month, currentMonthYear.year));
    }, [currentMonthYear]);

    const triggeredDateHandler = (k) => {
        setIsMarkingAttendance(true)
        setTriggeredDate(k)
        console.log(k, currentMonthYear)
    }

    const getStaffAttendance = async () => {
        const getStaffAttendanceResponse = await fetch(BASE_URL_API + "getStaffAttendance",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    staffIdentifier: staffIdentifier,
                    dateToBeMarked: `${currentMonthYear?.year}-${getMonthNumber(currentMonthYear?.month)}-${padNumber(triggeredDate?.date)}`,
                    monthYear: `${currentMonthYear?.year}-${getMonthNumber(currentMonthYear?.month)}`,
                })
            })

        if (!getStaffAttendanceResponse.ok) {
            console.log("something went wrong : Server Error")
        } else {
            const getStaffAttendanceRespo = await getStaffAttendanceResponse.json()
            if (getStaffAttendanceRespo.status === "success") {
                setAttendanceRecords(getStaffAttendanceRespo.response)
                setGeneralShift({ startTime: getStaffAttendanceRespo?.shiftData?.shiftStartTime, endTime: getStaffAttendanceRespo?.shiftData?.shiftEndTime })
                setWeekOff(getStaffAttendanceRespo?.shiftData?.weekOff)
            } else {
                if (getStaffAttendanceRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAttendanceRecords([])
                    setGeneralShift({ startTime: getStaffAttendanceRespo?.shiftData?.shiftStartTime, endTime: getStaffAttendanceRespo?.shiftData?.shiftEndTime })
                    setWeekOff(getStaffAttendanceRespo?.shiftData?.weekOff)
                    console.log(getStaffAttendanceRespo)
                }
            }
        }
    }

    useEffect(() => {
        getStaffAttendance()
    }, [currentMonthYear, staffIdentifier])


    function calculateAttendanceStats(currentMonth, currentYear, generalShift, weekOff, attendanceRecords, holidays) {
        const totalDays = new Date(currentYear, currentMonth, 0).getDate();

        let payableDays = 0;
        let presentDays = 0;
        let onDuty = 0;
        let paidLeaves = 0;
        let weekOffs = 0;
        let halfDays = 0;
        let lateMarks = 0;
        let overtime = 0;
        let overtimeHours = 0;
        let shortTimeHours = 0;

        // Convert time string to minutes
        // const timeToMinutes = time => {
        //     const [h, m] = time?.split(":")?.map(Number);
        //     return h * 60 + m;
        // };

        const timeToMinutes = time => {
            if (typeof time !== "string" || !time.includes(":")) return null; // Handle invalid input

            const parts = time.split(":");
            if (parts.length !== 2) return null; // Ensure we have exactly 2 parts

            const [h, m] = parts.map(num => Number(num.trim()));

            if (isNaN(h) || isNaN(m) || h < 0 || m < 0 || m >= 60) return null; // Validate parsed numbers

            return h * 60 + m;
        };

        // Merge overlapping time intervals
        const mergeIntervals = entries => {
            entries.sort((a, b) => timeToMinutes(a?.inTime) - timeToMinutes(b?.inTime));
            let merged = [];
            for (let entry of entries) {
                let inTime = timeToMinutes(entry?.inTime);
                let outTime = timeToMinutes(entry?.outTime);
                if (merged.length > 0 && inTime <= merged[merged.length - 1].outTime) {
                    merged[merged.length - 1].outTime = Math.max(merged[merged.length - 1].outTime, outTime);
                } else {
                    merged.push({ inTime, outTime });
                }
            }
            return merged;
        };

        for (let day = 1; day <= totalDays; day++) {
            const dateStr = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            const dateObj = new Date(dateStr);
            const dayName = dateObj.toLocaleDateString('en-US', { weekday: 'long' });

            let attendance = attendanceRecords.find(record => record.dateToBeMarked === dateStr);

            if (holidays?.includes(dateStr)) {
                continue; // Skip holidays
            }

            payableDays++;

            if (dayName === weekOff) {
                weekOffs++; // Directly count week-offs
            }

            if (attendance && attendance?.attendanceEntries?.length > 0) {
                presentDays++;

                let workedMinutes = 0;
                let shiftStart = timeToMinutes(generalShift?.startTime);
                let shiftEnd = timeToMinutes(generalShift?.endTime);
                let shiftDuration = shiftEnd - shiftStart; // Total shift duration in minutes

                let mergedEntries = mergeIntervals(attendance?.attendanceEntries);
                let earliestInTime = Math.min(...mergedEntries.map(e => e.inTime));

                // Calculate total worked minutes
                mergedEntries.forEach(entry => {
                    workedMinutes += (entry.outTime - entry.inTime);
                });

                // Check for late mark
                if (earliestInTime > shiftStart + 30) {
                    lateMarks++;
                }

                // Calculate overtime and short time hours
                let extraMinutes = workedMinutes - shiftDuration;
                if (extraMinutes > 0) {
                    overtime++;
                    overtimeHours += extraMinutes;
                } else if (extraMinutes < 0) {
                    shortTimeHours += Math.abs(extraMinutes);
                }
            } else {
                if (dayName !== weekOff) {
                    paidLeaves++; // Only count paid leave if it's a working day and no attendance
                }
            }
        }


        return {
            payableDays,
            presentDays,
            onDuty,
            paidLeaves,
            weekOffs,
            halfDays,
            lateMarks,
            overtime,
            overtimeHours,
            shortTimeHours
        };
    }





    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                {isShiftManagerOpen &&
                    <MESAShiftManager isOpen={isShiftManagerOpen}
                        onClose={() => { 
                            setIsShiftManagerOpen(false) 
                            getStaffAttendance()
                        }}
                        staffIdentifier={staffIdentifier}
                        generalShift={generalShift} weekOff={weekOff}
                    />
                }
                <div className={styles.headerWrapper}>
                    <div className={styles.dateChangeWrapper}>
                        <FiChevronLeft size={20} onClick={() => handleMonthChange(-1)} />
                        <div className={styles.currentDate} onClick={toggleDatePicker}>
                            {currentMonthYear?.month?.substr(0, 3)} {currentMonthYear?.year}
                        </div>
                        <FiChevronRight size={20} onClick={() => handleMonthChange(1)} />
                        {showDatePicker && (
                            <div ref={datePickerRef} className={styles.datePicker}>
                                <div className={styles.datePickerHeader}>Select Month & Year</div>

                                <div className={styles.monthGrid}>
                                    {months.map(month => (
                                        <div key={month} className={styles.monthItem}
                                            onClick={() => selectMonthYear(month, currentMonthYear.year)}
                                        >
                                            {month.substr(0, 3)}
                                        </div>
                                    ))}
                                </div>

                                <div className={styles.yearSelector}>
                                    <button className={styles.yearButton}
                                        onClick={() => setStartYear(prev => prev - 2)}
                                    >‹</button>

                                    <div className={styles.yearGrid}>
                                        {Array.from({ length: 2 }, (_, i) => startYear + i).map(year => (
                                            <div key={year} className={styles.yearItem}
                                                onClick={() => selectMonthYear(currentMonthYear.month, year)}
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>

                                    <button className={styles.yearButton}
                                        onClick={() => setStartYear(prev => prev + 2)}
                                    >›</button>
                                </div>
                            </div>
                        )}
                    </div>



                    <div className={styles.iconsWrapper}>
                        <div className={activeView === 'List' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('List')}>
                            <FaList color={activeView === 'List' ? '#ffffff' : '#858585'} />
                        </div>
                        <div className={activeView === 'Tabular' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('Tabular')}>
                            <CiGrid41 color={activeView === 'Tabular' ? '#ffffff' : '#858585'} />
                        </div>
                        <div className={activeView === 'Calendar' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('Calendar')}>
                            <FaCalendar color={activeView === 'Calendar' ? '#ffffff' : '#858585'} />
                        </div>
                    </div>
                </div>

                <div className={styles.mainBody}>
                    {activeView === "List" && <MESAList days={days} attendanceRecords={attendanceRecords} selectedMonth={currentMonthYear?.month} selectedYear={currentMonthYear?.year} triggeredDateHandler={triggeredDateHandler} staffIdentifier={staffIdentifier} />}
                    {activeView === "Tabular" && <MESATabular />}
                    {activeView === "Calendar" && <MESACalendar />}
                </div>
                {isMarkingAttendance && <MESAEntryPopUp isOpen={isMarkingAttendance} onClose={() => setIsMarkingAttendance(false)} triggeredDate={triggeredDate} currentMonthYear={currentMonthYear} staffIdentifier={staffIdentifier} />}

                <div className={styles.mainFooter}>
                    <div className={styles.footerWrapper} >
                        <div className={styles.analysisWrapper}>
                            <div className={styles.payDays}>
                                <div className={styles.attSumCaption}>
                                    Payable Days
                                </div>
                                <div className={styles.attSumValue}>
                                    {calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.payableDays} Days
                                </div>
                            </div>
                            <div className={styles.presentDays} >
                                <div className={styles.attSumCaption}>
                                    Present Days
                                </div>
                                <div className={styles.attSumValue}>
                                    {calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.presentDays} Days
                                </div>
                            </div>
                            <div className={styles.onDuty} >
                                <div className={styles.attSumCaption}>
                                    On Duty
                                </div>
                                <div className={styles.attSumValue}>
                                    {calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.onDuty} Days
                                </div>
                            </div>
                            <div className={styles.paidLeaves}>
                                <div className={styles.attSumCaption}>
                                    Paid Leaves
                                </div>
                                <div className={styles.attSumValue}>
                                    0 Days
                                </div>
                            </div>
                            <div className={styles.holidays}>
                                <div className={styles.attSumCaption}>
                                    Holidays
                                </div>
                                <div className={styles.attSumValue}>
                                    0 Days
                                </div>
                            </div>
                            <div className={styles.holidays}>
                                <div className={styles.attSumCaption}>
                                    Over Time Hours
                                </div>
                                <div className={styles.attSumValue}>
                                    {parseFloat(calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.overtimeHours / 60).toFixed(2)} Hours
                                </div>
                            </div>
                            <div className={styles.holidays}>
                                <div className={styles.attSumCaption}>
                                    Short Time Hours
                                </div>
                                <div className={styles.attSumValue}>
                                    {parseFloat(calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.shortTimeHours / 60).toFixed(2)} Hours
                                </div>
                            </div>
                            <div className={styles.weekoffs}>
                                <div className={styles.attSumCaption}>
                                    Week-Off
                                </div>
                                <div className={styles.attSumValue}>
                                    {calculateAttendanceStats(getMonthNumber(currentMonthYear?.month), currentMonthYear?.year, generalShift, weekOff, attendanceRecords)?.weekOffs} Days
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.shiftControl}>

                                <div className={styles.generalShiftWrapper}>
                                    <div className={styles.gswCaption} >General Shift</div>
                                    <div className={styles.gswValue} >
                                        {generalShift?.startTime} -  {generalShift?.endTime}
                                    </div>
                                </div>
                                <div className={styles.shiftMenu} onClick={() => setIsShiftManagerOpen(true)} >
                                    <BsThreeDotsVertical />
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div >
    );
};

export default ManageEachStaffAttendance;
