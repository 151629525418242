import React, { useContext, useEffect, useState } from 'react'
import EachServiceList from '../../Components/Services/EachServiceList'
import UpdateService from '../../Components/Services/UpdateService'
import StoresHeader from '../../Components/Stores/StoresHeader'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './StorePriceList.module.css'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { MdMenuBook } from "react-icons/md";
import AddSectionPopUp from '../../Components/Menu/AddSectionPopUp'
import { FiPlus } from "react-icons/fi";




const StorePriceList = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const { serviceIdentifier } = useParams()

    const [isFetchingSection, setIsFetchingSection] = useState(false)
    const [sections, setSections] = useState([])
    const [activeSection, setActiveSection] = useState(null)
    const [isAddingNewSection, setIsAddingNewSection] = useState(false)

    const getAllServicesSection = async () => {
        setIsFetchingSection(true)
        console.log(authCtx.token)
        const getAllServicesSectionResponse = await fetch(BASE_URL_API + "getAllServicesSection",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllServicesSectionResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAllServicesSectionRespo = await getAllServicesSectionResponse.json();
            if (getAllServicesSectionRespo.status === "success") {
                setSections(getAllServicesSectionRespo.response);
                setActiveSection(getAllServicesSectionRespo?.response[0]?.sectionIdentifier)
            } else {
                if (getAllServicesSectionRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(getAllServicesSectionRespo)
                }
            }
        }
        setIsFetchingSection(false)
    }

    useEffect(() => {
        console.log("Hitting now...")
        getAllServicesSection();
    }, [activeStore?.storeIdentifier])

    const newSectionCreationHandler = () => {
        setIsAddingNewSection(false)
        toast(`Successfully created`)
        getAllServicesSection()
    }


    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                {/* <StoresHeader /> */}
                {isAddingNewSection && <AddSectionPopUp isOpen={isAddingNewSection} onClose={() => setIsAddingNewSection(false)} onSuccess={() => newSectionCreationHandler()} />}
                {serviceIdentifier && <UpdateService serviceIdentifier={serviceIdentifier} />}

                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <div className={styles.mainHeaderContainer}>
                                <div className={styles.title}>
                                    Store Price List
                                </div>
                                <div className={styles.subTitle}>
                                    All items in your catalogue.
                                </div>
                            </div>
                            <div className={styles.sectionContainer}>
                                {sections?.length > 0 ?
                                    <div className={styles.allSectionsWrapper}>
                                        {sections?.map((item, index) => (
                                            <button onClick={() => setActiveSection(item?.sectionIdentifier)}
                                                key={index}
                                                className={item?.sectionIdentifier === activeSection ? styles.genderIdentifierBtnSelected : styles.genderIdentifierBtn}
                                            >
                                                {item?.section}
                                            </button>
                                        ))}
                                        <div className={styles.addSectionSmall}>
                                            <button onClick={() => setIsAddingNewSection(true)} >
                                                <FiPlus size={24} />
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.addSection}>
                                        <button onClick={() => setIsAddingNewSection(true)} >Add Section</button>
                                    </div>
                                }

                            </div>
                        </div>
                        {sections?.length > 0 ?
                            <div className={styles.mainBody}>
                                <EachServiceList sections={sections} activeSection={activeSection} />
                            </div>
                            :
                            <div className={styles.mainBodyNotAdded}>
                                <div className={styles.notFoundTxt}>
                                    <div>
                                        <MdMenuBook size={148} color="#dfdfdf" />
                                    </div>
                                    <div className={styles.naTxt}>
                                        Manage all your Price list / Menu / Products / Items / Services and stocks / inventory
                                    </div>
                                </div>
                                <div className={styles.iconGuidelines}>
                                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/LokaciPro/project-assets/lets-start-with-adding-sections.png" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        )
    }

}

export default StorePriceList