import React, { useState, useEffect, useContext } from 'react';
import styles from './ComAllCampaigns.module.css';
import { motion } from 'framer-motion';
import { FaFilter, FaSort, FaSearch, FaSync, FaChevronDown, FaPlus } from 'react-icons/fa';
import CampaignPreviewModal from '../../Components/Communications/Campaigns/CampaignPreviewModal';
import { Link } from 'react-router-dom'
import CreateCampaignPopup from '../../Components/Communications/Campaigns/CreateCampaignPopup';
import { FiDownload } from 'react-icons/fi';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { BASE_URL_API } from '../../References/URLs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const ComAllCampaigns = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [campaignsData, setCampaignsData] = useState([])
    const [filteredData, setFilteredData] = useState([]);

    const nav = useNavigate()
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const campaignCreationHandler = (k) => {
        toast(`Campaign successfully created!`)
        nav(`/communications/campaign/${k}/details`)
    }

    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [filters, setFilters] = useState({ status: '', type: '', date: '' });

    const getAllCampaigns = async () => {
        setLoading(true)
        const getAllCampaignsResponse = await fetch(BASE_URL_API + "getAllCampaigns",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier
                })
            });

        if (!getAllCampaignsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllCampaignsRespo = await getAllCampaignsResponse.json()
            if (getAllCampaignsRespo?.status === "success") {
                setCampaignsData(getAllCampaignsRespo?.response)
                setFilteredData(getAllCampaignsRespo?.response)
            } else {
                if (getAllCampaignsRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getAllCampaignsRespo?.response)
        }
        setLoading(false)
    }


    // useEffect(() => {
    //     getAllCampaigns()
    // }, [searchQuery, sortBy, sortOrder, filters, currentPage, rowsPerPage]);

    useEffect(() => {
        getAllCampaigns()
    }, []);

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenPreview = (campaign) => {
        setSelectedCampaign(campaign);
        setIsModalOpen(true);
    };

    const handleClosePreview = () => {
        setSelectedCampaign(null);
        setIsModalOpen(false);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        const query = e.target.value.toLowerCase();
        const filtered = campaignsData.filter((campaign) =>
            campaign.name.toLowerCase().includes(query)
        );
        setFilteredData(filtered);
    };

    const handleSort = (field) => {
        const newSortOrder = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(field);
        setSortOrder(newSortOrder);

        const sorted = [...filteredData].sort((a, b) =>
            newSortOrder === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1)
        );
        setFilteredData(sorted);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const exportToCSV = () => {
        const headers = ['Campaign Name', 'Date', 'Status', 'Type', 'Reach', 'Clicks', 'Budget', 'Performance'];
        const rows = campaignsData.map((campaign) => [
            campaign.campaignName,
            campaign.createdOn,
            campaign.status,
            campaign.type,
            campaign.reach,
            campaign.clicks,
            `$${campaign.budget}`,
            campaign.performance,
        ]);

        let csvContent = "data:text/csv;charset=utf-8," + [headers, ...rows].map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "campaigns.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toggleStatus = (id) => {
        setFilteredData((prevData) =>
            prevData.map((campaign) =>
                campaign?.campaignIdentifier === id
                    ? { ...campaign, status: campaign.status === 'Active' ? 'Inactive' : 'Active' }
                    : campaign
            )
        );
    };

    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >

            {/* Campaign Preview Modal */}
            <CampaignPreviewModal
                isOpen={isModalOpen}
                onClose={handleClosePreview}
                campaign={selectedCampaign}
            />
            <header className={styles.header}>
                <div>All Campaigns</div>
                <div className={styles.searchFilter}>
                    <div className={styles.searchBar}>
                        <FaSearch className={styles.icon} />
                        <input
                            type="text"
                            placeholder="Search campaigns..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className={styles.filters}>
                        <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="Completed">Completed</option>
                        </select>
                        <select onChange={(e) => handleFilterChange('type', e.target.value)}>
                            <option value="">Type</option>
                            <option value="WhatsApp">WhatsApp</option>
                            <option value="SMS">SMS</option>
                        </select>
                        <select onChange={(e) => handleFilterChange('date', e.target.value)}>
                            <option value="">Date</option>
                            <option value="Last 7 Days">Last 7 Days</option>
                            <option value="Last 30 Days">Last 30 Days</option>
                        </select>
                        <button className={styles.exportBtn} onClick={exportToCSV}>CSV <FiDownload /></button>
                    </div>
                    <div>
                        {isPopUpOpen && <CreateCampaignPopup isOpen={isPopUpOpen} onClose={() => setIsPopUpOpen(false)} onSuccess={campaignCreationHandler} />}
                        <button className={styles.createNewCampaign} onClick={() => setIsPopUpOpen(true)} >
                            <FaPlus size={18} />
                        </button>
                    </div>
                </div>
            </header>
            {loading ? (
                <div className={styles.loader}>
                    <FaSync className={styles.loaderIcon} />
                    <p>Loading campaigns...</p>
                </div>
            ) : (
                <>
                    <div className={styles.table}>
                        <div className={styles.tableHeader}>
                            <div onClick={() => handleSort('campaignName')}
                                className={styles.campaignName}
                                >
                                Campaign Name <FaSort className={styles.sortIcon} />
                            </div>
                            <div onClick={() => handleSort('createdOn')}
                                className={styles.createdOn}
                            >
                                Date <FaSort className={styles.sortIcon} />
                            </div>
                            <div className={styles.status} >Status</div>
                            <div className={styles.type} >Type</div>
                            <div className={styles.reach} >Reach</div>
                            <div className={styles.budget} >Budget</div>
                            <div className={styles.performance}>Performance</div>
                            <div className={styles.action} >Action</div>
                        </div>
                        <div className={styles.tableBody}>
                            {paginatedData.map((campaign) => (
                                <motion.div
                                    className={styles.tableRow}
                                    key={campaign.campaignIdentifier}
                                    whileHover={{ scale: 1.009 }}
                                >
                                    <div className={styles.campaignName}>{campaign.campaignName}</div>
                                    <div className={styles.createdOn} >{campaign.createdOn}</div>
                                    <div className={styles.status} >{campaign.status}
                                            {campaign.status === 'Active' ? 'Deactivate' : 'Activate'}
                                    </div>
                                    <div className={styles.type} >{campaign.type}</div>
                                    <div className={styles.reach} >{campaign.reach}</div>
                                    <div className={styles.budget} >₹{campaign.budget}</div>
                                    <div className={styles.performance}>{campaign.performance}</div>
                                    <div className={styles.action}>
                                        {/* <button
                                            className={styles.previewBtn}
                                            onClick={() => handleOpenPreview(campaign)}
                                        >
                                            Preview
                                        </button> */}
                                        <Link to={`/communications/campaign/${campaign?.campaignIdentifier}/details`}>
                                            View
                                        </Link>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.paginationControls}>
                        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                            <option value={5}>5 rows per page</option>
                            <option value={10}>10 rows per page</option>
                            <option value={15}>15 rows per page</option>
                        </select>
                        <div className={styles.pagination}>
                            {Array.from(
                                { length: Math.ceil(filteredData.length / rowsPerPage) },
                                (_, i) => (
                                    <button
                                        key={i}
                                        className={`${styles.pageButton} ${currentPage === i + 1 ? styles.activePage : ''
                                            }`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </>
            )}
        </motion.div>
    );
};

export default ComAllCampaigns;
